import React from "react";

function logo({ width, height }) {
	return (
		<svg
			id="Layer_2"
			data-name="Layer 2"
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 121.948 47.94"
		>
			<g id="Layer_1" data-name="Layer 1">
				<path
					id="Path_5249"
					data-name="Path 5249"
					d="M12.029,36.61,2.479,2.037C2.214,1.359,1.4,1.014,0,1.12V.85H9.063v.27c-1.328-.053-2,.164-1.89.757a.2.2,0,0,0,.053.159l7.447,26.914L21.845,5.273l-.916-3.236c-.217-.677-1.005-1.023-2.484-.916V.85H27.56v.27c-1.4-.053-2,.164-1.939.757a.2.2,0,0,1,.053.159l7.5,26.914L41.259,2.037c.217-.646-.443-.97-1.833-.916V.85h4.64v.27c-1.51,0-2.373.27-2.537.885L31.067,36.61h-.518l-8.6-30.961L12.622,36.61Z"
					transform="translate(0 -0.474)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5250"
					data-name="Path 5250"
					d="M96.983,34.7v-.381c-.053-4.476,2.1-9.328,8.036-10.183,4.87-.376,7.659,1.939,8.359,7.013v.213H100.8a20.282,20.282,0,0,0,1.023,7.823c1.727,4.529,7.336,7.283,11.923,2.856a.4.4,0,0,0,.106.164,14.905,14.905,0,0,1-1.293,1.187c-7.969,5.393-15.589-1.771-15.589-8.682m3.83-3.542h9.329a8.573,8.573,0,0,0-.54-4.64,3.7,3.7,0,0,0-3.179-2.1.6.6,0,0,0-.27-.053,4.038,4.038,0,0,0-2,.483c-2.1,1.027-3.073,3.506-3.343,6.313m6.579,16.935a9.059,9.059,0,0,0,3.542,3.542v.376a9.6,9.6,0,0,0-3.453,3.453h-.483a9.076,9.076,0,0,0-3.347-3.4v-.443a8.926,8.926,0,0,0,3.4-3.542Z"
					transform="translate(-54.038 -13.431)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5251"
					data-name="Path 5251"
					d="M166.8,26.365h.164L164.6,36.548H147.28v-.213c1.293-.053,2.05-.27,2.156-.753V1.766c-.106-.443-.885-.646-2.156-.7V.85h8.629v.217c-1.24.053-1.886.27-2.05.7V35.587c.164.483.81.7,2.05.753,6.9,0,9.439-3.719,10.9-9.975"
					transform="translate(-82.073 -0.474)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5252"
					data-name="Path 5252"
					d="M212.641,0A17.967,17.967,0,0,1,230.5,18.121a17.855,17.855,0,1,1-35.707,0A18,18,0,0,1,212.641,0m4.821,2.267a13.782,13.782,0,0,0-10.626-.81,11.175,11.175,0,0,0-5.18,3.83,16.1,16.1,0,0,0-3.019,9.6c-.27,3.94.863,7.177,2.7,10.626A15.89,15.89,0,0,0,206.2,31.5c4.175,4.047,12.1,4.91,16.47.823,4.91-4.6,5.016-13.725,2.59-19.591-1.78-4.259-3.883-8.3-7.8-10.462m-3.883,11.6a9.032,9.032,0,0,0,3.542,3.542v.376a9.554,9.554,0,0,0-3.449,3.453h-.487a9.125,9.125,0,0,0-3.343-3.4v-.443a8.886,8.886,0,0,0,3.4-3.542Z"
					transform="translate(-108.549)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5253"
					data-name="Path 5253"
					d="M147.229,99.777l-.416-6.486c0-.133-.159-.19-.443-.173V93.07h1.7v.049c-.252,0-.385.031-.385.142v.031l.328,5.052,2.285-4.427-.04-.607q0-.2-.443-.173v-.049h1.7v.049c-.252,0-.376.031-.385.142v.031l.39,5.043,2.6-5.052c.049-.12-.053-.182-.314-.173v-.049h.885v.049c-.27,0-.443.053-.5.164l-3.338,6.495h-.1l-.421-5.818-2.984,5.809Z"
					transform="translate(-81.566 -51.864)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5254"
					data-name="Path 5254"
					d="M167.234,93.022l.04,1.9h-.031c-.022-1.173-.354-1.859-1.651-1.859-.221,0-.363.049-.4.128l-.607,2.834h.726c.478,0,.7-.23.66-.669h.04V97.37h-.04c0-.837-.19-1.275-.629-1.328h-.766l-.731,3.471c0,.084.12.133.345.142,1.293,0,1.921-.7,2.448-1.868h0l-.85,1.913H162.55v-.044c.244,0,.385-.058.443-.142l1.328-6.344c0-.08-.142-.12-.376-.128V93Z"
					transform="translate(-90.583 -51.825)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5255"
					data-name="Path 5255"
					d="M180.72,97.856h.031l-.841,1.913h-3.25v-.04c.243,0,.394-.053.443-.142l1.328-6.344c0-.084-.133-.124-.376-.133v-.04h1.616v.04c-.23,0-.363.049-.416.133l-1.328,6.344c0,.089.124.128.354.142,1.3,0,1.913-.7,2.439-1.873"
					transform="translate(-98.446 -51.864)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5256"
					data-name="Path 5256"
					d="M185.67,99.769v-.04c.243,0,.385-.053.443-.142l1.328-6.344c0-.084-.133-.124-.376-.133v-.04h1.62v.04c-.235,0-.367.049-.416.133l-1.328,6.344c0,.089.12.133.354.142v.04Z"
					transform="translate(-103.467 -51.864)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5257"
					data-name="Path 5257"
					d="M194.953,93.11c-.261,0-.394.031-.394.142v.031l.385,5.047,2.656-5.047c.062-.124-.049-.182-.323-.173v-.04h.885v.04c-.292,0-.465.049-.522.164l-3.431,6.495H194.1l-.5-6.477c-.022-.142-.164-.2-.443-.182v-.04h1.771Z"
					transform="translate(-107.641 -51.864)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5258"
					data-name="Path 5258"
					d="M206.68,93.022l.04,1.9h-.031c0-1.173-.354-1.859-1.647-1.859-.226,0-.367.049-.407.128l-.607,2.834h.731c.474,0,.7-.23.655-.669h.04V97.37h-.04c0-.837-.19-1.275-.629-1.328h-.766l-.731,3.471c0,.084.124.133.345.142,1.293,0,1.921-.7,2.448-1.868h.022l-.85,1.913H202v-.044c.243,0,.385-.058.443-.142l1.328-6.344c0-.08-.142-.12-.376-.128V93Z"
					transform="translate(-112.567 -51.825)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5259"
					data-name="Path 5259"
					d="M221.552,92.91a2.651,2.651,0,0,1,2.63,3.4,4.427,4.427,0,0,1-4.078,3.4,2.656,2.656,0,0,1-2.63-3.4,4.427,4.427,0,0,1,4.078-3.4m.81.443A2.453,2.453,0,0,0,220.4,93.2a2.838,2.838,0,0,0-1.125.717,3.878,3.878,0,0,0-.952,1.8,3.4,3.4,0,0,0,.08,1.992,2.383,2.383,0,0,0,3.746,1.275,4.028,4.028,0,0,0,1.266-3.675,2.812,2.812,0,0,0-1.054-1.961"
					transform="translate(-121.148 -51.775)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5260"
					data-name="Path 5260"
					d="M237.672,93.119c-.071,0-.4.031-.443.155l-1.386,6.5h-.071l-2.121-6.517-1.328,6.3c-.031.133.133.164.235.173h.142v.04h-.85v-.04h.066a.553.553,0,0,0,.19-.031.221.221,0,0,0,.164-.133L233.6,93.26c0-.142-.283-.151-.385-.142V93.07h1.173l1.731,5.251c.354-1.691.722-3.378,1.076-5.061.027-.142-.283-.151-.376-.142V93.07h.85Z"
					transform="translate(-129.201 -51.864)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5261"
					data-name="Path 5261"
					d="M249.689,93.336l.283.173c.243.164.443,0,.545-.443h.044l-.567,2.214v-.195c.275-1.771-1.284-2.537-3.015-1.944a2.9,2.9,0,0,0-1.125.722,3.843,3.843,0,0,0-.992,1.833,3.365,3.365,0,0,0,.093,2,3.1,3.1,0,0,0,.159.443,2.023,2.023,0,0,0,.509.677,2.214,2.214,0,0,0,2.386.527,3.931,3.931,0,0,0,1.395-.828l.031.027a4.427,4.427,0,0,1-2.054,1.094,2.7,2.7,0,0,1-3.338-3.347A4.481,4.481,0,0,1,247.1,93.03a3.436,3.436,0,0,1,1.005-.151,2.825,2.825,0,0,1,1.558.443Z"
					transform="translate(-135.95 -51.758)"
					fill="#1c1b1b"
				/>
				<path
					id="Path_5262"
					data-name="Path 5262"
					d="M261.084,93.022l.04,1.9h-.009c0-1.173-.354-1.859-1.647-1.859-.221,0-.363.049-.407.128l-.607,2.834h.731c.474,0,.7-.23.655-.669h.04V97.37h-.04c.022-.837-.19-1.275-.624-1.328h-.77l-.731,3.471c0,.084.124.133.345.142,1.3,0,1.921-.7,2.448-1.868h.022l-.85,1.913H256.44v-.044a.536.536,0,0,0,.443-.142l1.328-6.344c0-.08-.142-.12-.376-.128V93Z"
					transform="translate(-142.904 -51.825)"
					fill="#1c1b1b"
				/>
			</g>
		</svg>
	);
}

export default logo;
