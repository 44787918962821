import { deleteResource, getResource, postResource } from "../../services";

export const uploadEventCover = (body, onSuccess, onFail) => {
  const path = "event/imageEventUpload ";
  postResource(path, body, onSuccess, onFail, true, true);
};

export const postEvent = (body, onSuccess, onFail) => {
  const path = "event";
  postResource(path, body, onSuccess, onFail);
};
export const getAllEvents = (data, onSuccess, onFail) => {
  const { timeZone } = data;
  const path = `event/getAllEventsForAdmin?timeZone=${timeZone}`;
  getResource(path, onSuccess, onFail);
};
export const getEventById = (data, onSuccess, onFail) => {
  const { timeZone, eventId } = data;
  const path = `event/getEventByIdForAdmin?eventId=${eventId}`;
  getResource(path, onSuccess, onFail);
};

export const deleteEventById = (body, onSuccess, onFail) => {
  const { id } = body;
  const path = `event/deleteEvent?id=${id}`;
  postResource(path, body, onSuccess, onFail);
};
