import React, { useState, useEffect } from "react";
import {
  Modal,
  Col,
  Row,
  Button,
  Radio,
  Input,
  DatePicker,
  Switch,
  InputNumber,
  Checkbox,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import ConfirmationModal from "../../../common/components/confirmationModal/confirmationModal";
import CalendarHeader from "../calendarHeader/calendarHeader.js";
import filledStars from "../../../../assets/icons/filledStar.svg";
import emptyStars from "../../../../assets/icons/emptyStar.svg";
import play from "../../../../assets/icons/play.svg";
import { Player, BigPlayButton } from "video-react";
import { mediaBaseUrl } from "../../../../services";
import { Alert } from "react-bootstrap";
import {
  ActivatedAndDeactivateUsers,
  AddCoachZoomEmail,
  EditAvailability,
  EditGuruDetails,
  NotifyChangeSchedule,
} from "../../network";
import DiscountModal from "../DiscountModal/DiscountModal";
//import moment from "moment";
//import TimezoneSelect, { allTimezones } from "react-timezone-select";
import moment from "moment-timezone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import validator from "validator";
import { toastError } from "../../../../helpers/toast";
import { isSummerTime } from "../../../../App";
import "./guruModal.css";

function GuruModal({ isModalVisible, toggleModal, record }) {
  const [radioValue, setRadioValue] = useState();
  const [isRecursive, updateIsRecursive] = useState(false);
  const [isConfModalVis, toggleConfModal] = useState(false);
  const [isVidModalVis, toggleVidModal] = useState(false);
  const [currentDates, setCurrentDates] = useState([]);
  const [currentSchedules, setCurrentSchedules] = useState([]);
  const [recursiveSchedules, updateRecursiveSchedules] = useState([]);
  const [isDiscountModalVisible, updateDiscountModalVisible] = useState(false);
  const [selectedTimeSlot, updateSelectedTimeSlot] = useState(null);
  const [selectedDate, updateSelectedDate] = useState(null);
  const [sessionPeriod, updateSessionPeriod] = useState(60);
  const [rank, updateRank] = useState(record.rank ? record.rank : "");
  const [schedules, updateSchedules] = useState([]);
  const [zoomEmail, updateZoomEmail] = useState("");
  //const [selectedTimezone, setSelectedTimezone] = useState({});
  const [alert, updateAlert] = useState(null);
  const [pricePerHour, updatePricePerHour] = useState("");
  const [fullName, setFullName] = useState(null);
  const [bio, setBio] = useState(null);
  const [isDataRecieved, updateIsDataRecieved] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [isFirstSessionFree, updateIsFirstSessionFree] = useState(false);
  let filledStar = [];
  let emptyStar = [];
  const rate = record.rating ? record.rating : 5;

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().subtract(1, "days");
  };

  const disabledDateTime = () => ({
    disabledMinutes: () => {
      var disabledMinutes = [];
      for (let i = 0; i < 60; i++) {
        if (i % 15) {
          disabledMinutes.push(i);
        }
      }
      return disabledMinutes;
    },
  });

  useEffect(() => {
    var schedules = [];
    for (let dayNumber = 0; dayNumber <= 365; dayNumber++) {
      schedules.push({
        fullDate: moment().add(dayNumber, "days").format("YYYY-MM-DD"),
        times: [],
      });
      record.schedulesNew.forEach((date) => {
        if (
          date.fullDate === moment().add(dayNumber, "days").format("YYYY-MM-DD")
        ) {
          schedules[schedules.length - 1].times = date.times;
        }
      });
    }
    let recursiveSchedulesList = [];

    const daysNames = [];
    for (let dayNumber = 0; dayNumber <= 6; dayNumber++) {
      const dayName = moment().add(dayNumber, "days").format("dddd");
      daysNames.push(dayName);
    }

    for (let dayNumber = 0; dayNumber <= 6; dayNumber++) {
      const newDateObject = {
        fullDate: daysNames[dayNumber],
        times: [],
      };
      recursiveSchedulesList.push(newDateObject);
    }
    recursiveSchedulesList.forEach((recursiveSchedulee, i) => {
      record?.recursiveSchedules?.forEach((finalRecursiveSchedule, j) => {
        if (recursiveSchedulee.fullDate === finalRecursiveSchedule.fullDate) {
          recursiveSchedulesList[i] = record?.recursiveSchedules[j];
        }
      });
    });
    updateRecursiveSchedules(recursiveSchedulesList);
    updateSchedules(schedules);
    updateZoomEmail(record.zoomEmail ? record.zoomEmail : "");
  }, [record.schedulesNew, record.zoomEmail, record.recursiveSchedules]);

  const onRecursiveSwitchChange = (checked) => {
    updateIsRecursive(checked);
  };

  const handleChooseTime = () => {
    let schedulesArr = [...schedules];
    const overLappedDates = [];
    let recursiveList = [...recursiveSchedules];

    if (isRecursive) {
      const newSession = {
        startDateTime: moment(selectedDate)
          .tz(isSummerTime ? "Africa/Addis_Ababa" : "Africa/Cairo")
          .format(),
        endDateTime: moment(selectedDate)
          .add(sessionPeriod, "minutes")
          .tz(isSummerTime ? "Africa/Addis_Ababa" : "Africa/Cairo", true)
          .format(),
        period: sessionPeriod,
        isRecursive: isRecursive,
        notSaved: true,
        // id: uuidv4(),
      };
      let currentDayNumForRecursive = recursiveList.findIndex(
        (schedule) =>
          schedule.fullDate === moment(newSession.startDateTime).format("dddd")
      );

      var isRecursiveNotOverlapped = recursiveList[
        currentDayNumForRecursive
      ]?.times.every((time) => {
        const sessionStartTime = moment(time.startDateTime).tz("GMT").valueOf();
        const sessionEndTime = moment(time.endDateTime).tz("GMT").valueOf();
        const newSessionStartTime = moment(newSession.startDateTime)
          .tz("GMT")
          .valueOf();
        const newSessionEndTime = moment(newSession.endDateTime)
          .tz("GMT")
          .valueOf();
        return !(
          newSessionStartTime < sessionEndTime &&
          newSessionEndTime > sessionStartTime
        );
      });

      if (isRecursiveNotOverlapped) {
        recursiveList[currentDayNumForRecursive].times.push(newSession);
        updateRecursiveSchedules(recursiveList);
      }
    }
    for (let dayIndex = 0; dayIndex <= schedulesArr.length; dayIndex += 7) {
      const startTime = moment(selectedDate)
        .add(dayIndex, "days")
        .tz(isSummerTime ? "Africa/Addis_Ababa" : "Africa/Cairo")
        .format();
      const endTime = moment(selectedDate)
        .add(dayIndex, "days")
        .add(sessionPeriod, "minutes")
        .tz(isSummerTime ? "Africa/Addis_Ababa" : "Africa/Cairo")
        .format();

      let currentDayNum = schedulesArr.findIndex(
        (schedule) =>
          schedule.fullDate === moment(startTime).format("YYYY-MM-DD")
      );
      if (currentDayNum >= 0) {
        const getTimeOverlap = (time, startTime, endTime) => {
          const sessionStartTime = moment(time.startDateTime)
            .tz("GMT")
            .valueOf();

          const sessionEndTime = moment(time.endDateTime).tz("GMT").valueOf();
          const newSessionStartTime = moment(startTime).tz("GMT").valueOf();
          const newSessionEndTime = moment(endTime).tz("GMT").valueOf();

          return !(
            newSessionStartTime < sessionEndTime &&
            newSessionEndTime > sessionStartTime
          );
        };

        const isNotOverlapped =
          schedules[currentDayNum]?.times.every((time) =>
            getTimeOverlap(time, startTime, endTime)
          ) &&
          (schedules[currentDayNum - 1]
            ? schedules[currentDayNum - 1]?.times.every((time) =>
                getTimeOverlap(time, startTime, endTime)
              )
            : true) &&
          (schedules[currentDayNum + 1]
            ? schedules[currentDayNum + 1]?.times.every((time) =>
                getTimeOverlap(time, startTime, endTime)
              )
            : true);
        if (isNotOverlapped) {
          if (currentDayNum >= 0) {
            schedulesArr[currentDayNum]?.times.push({
              startDateTime: startTime,
              endDateTime: endTime,
              period: sessionPeriod,
              isRecursive: isRecursive,
              notSaved: true,
            });
            schedulesArr[currentDayNum].times = schedulesArr[
              currentDayNum
            ].times.sort((a, b) => {
              return a.startDateTime < b.startDateTime ? -1 : 1;
            });

            updateSchedules(schedulesArr);
            updateSelectedDate(
              moment(selectedDate)
                .add(sessionPeriod, "minutes")
                .tz(isSummerTime ? "Africa/Addis_Ababa" : "Africa/Cairo")
            );
          }
        } else {
          if (schedules[currentDayNum]?.fullDate) {
            overLappedDates.push(schedules[currentDayNum]?.fullDate);
          }
        }
        if (!isRecursive) {
          if (overLappedDates.length) {
            toastError(`You have already a session within the range !`);
          }
          return;
        }
      }
    }

    if (overLappedDates.length) {
      toastError(
        `One or more session/s were not added because you have a sessions within the range!`
      );
    }
  };

  const handleSaveEmail = () => {
    if (validator.isEmail(zoomEmail) || zoomEmail.length === 0) {
      let data = {
        coachId: record._id,
        zoomEmail,
      };
      AddCoachZoomEmail(
        data,
        (success) => {
          updateAlert({ success: true, message: "Added Successfully" });
          setTimeout(() => {
            updateAlert(null);
          }, 4000);
        },
        (fail) => {
          console.log(fail);
        }
      );
    } else {
      updateAlert({ success: false, message: "Please type a valid email" });
    }
  };

  const handleSubmit = () => {
    if (radioValue === "block") {
      toggleConfModal(!isConfModalVis);
    } else {
      updateLoading(true);
      let body = {
        id: record._id,
        isActive: true,
        type: 2,
        rank: parseInt(rank),
      };
      let availabilityData = {
        id: record._id,
        schedulesNew: schedules,
        recursiveSchedules,
      };

      EditAvailability(availabilityData, (success) => {
        NotifyChangeSchedule(
          {},
          (success) => {
            ActivatedAndDeactivateUsers(
              body,
              (success) => {
                if (success.code === 1) {
                  if (
                    (!pricePerHour && pricePerHour !== 0) ||
                    parseInt(pricePerHour) < 0
                  ) {
                    toastError("Please add a valid price per hour!");
                    updateLoading(false);
                  } else {
                    let publicData = {
                      coachId: record._id,
                      pricePerHour,
                      coachBio: bio,
                      name: fullName,
                      isFirstSessionFree,
                    };
                    EditGuruDetails(
                      publicData,
                      (success) => {
                        if (success.status === 1) {
                          toggleModal();
                        }
                      },
                      (fail) => {
                        updateLoading(false);
                      }
                    );
                  }
                }
              },
              (fail) => {
                updateLoading(false);
              }
            );
          },
          (fail) => {
            updateLoading(false);
          }
        );
      });
    }
  };

  useEffect(() => {
    setRadioValue(record.isActive ? "active" : "block");
    setFullName(record.fullName);
    setBio(record.coachBio);
    updatePricePerHour(record.pricePerHour);
    updateIsDataRecieved((prevState) => !prevState);
    updateIsFirstSessionFree(record.isFirstSessionFree);
  }, [record]);

  filledStar.length = Math.ceil(rate);
  emptyStar.length = 5 - Math.ceil(rate);
  filledStar.fill("filled");
  emptyStar.fill("empty");

  useEffect(() => {
    let currentSchedules = [];
    if (schedules) {
      schedules.forEach((schedule) => {
        currentDates.forEach((date) => {
          if (date === schedule.fullDate) {
            currentSchedules.push(schedule.times);
          }
        });
      });
      setCurrentSchedules(currentSchedules);
    }
    // let currentSchedules = [];
    // if (schedules) {
    //   schedules.forEach((schedule) => {
    //     currentDates.forEach((date) => {
    //       if (date === schedule.fullDate) {
    //         currentSchedules.push(schedule.times, uuidv4());
    //       }
    //     });
    //   });
    //   setCurrentSchedules(currentSchedules);
    // }
  }, [currentDates, schedules]);

  const validatePrcPerHour = (e) => {
    if (!(e.code.includes("Digit") || e.code.includes("Numpad")))
      return e.preventDefault();
  };

  const onFreeSessionChange = (e) => {
    updateIsFirstSessionFree(e.target.checked);
  };

  function roundNearest1(num) {
    return Math.round(num / 1) * 1;
  }

  const handleDeleteTime = (event, selectedTime) => {
    //**************************************************************************************** */
    // step1) Target Spicific schedule time and update view upon spicific time deleted
    const copyCurrentSchedules = [...currentSchedules]; // Create a copy of the current schedules array
    const targetElement = event.target.parentElement.parentElement; // Identify the target DOM element
    // const targetIndx = +targetElement.id;
    const parentElement = targetElement.parentElement; // Get the parent element of the target
    const parentIndx = +parentElement.id; // Retrieve the index from the parent element's ID

    // Filter out the selected time from the schedules

    const filteredTimes = copyCurrentSchedules[parentIndx].filter(
      (schedules, index) => {
        return schedules.startDateTime !== selectedTime.startDateTime; // Compare startDateTime to filter out specific time
      }
    );

    copyCurrentSchedules[parentIndx] = filteredTimes; // Update the schedules with the filtered times
    setCurrentSchedules(copyCurrentSchedules); // Set the updated schedules in the state

    //**************************************************************************************** */
    // step2) Target and update schedules
    const copySchedules = [...schedules]; // Create a copy of the schedules array
    const targetDate = selectedTime.startDateTime; // Extract the date of the selected time

    // Find the schedules matching the target date
    const targetItem = copySchedules.filter((date, index) => {
      return date.fullDate === targetDate.slice(0, 10); // Compare fullDate to find the matching date
    });

    // Filter out the target time from the schedules
    let deleteTargetTime = targetItem.map((times) => {
      return times.times.filter((time) => {
        return time.startDateTime !== targetDate; // Compare startDateTime to filter out specific time
      });
    });

    // Find the index of the schedules matching the target date
    const targetIndex = copySchedules.findIndex((date, index) => {
      return date.fullDate === targetDate.slice(0, 10); // Find index based on the matching date
    });

    if (selectedTime.isRecursive) {
      // for (let i = targetIndex; i < copySchedules.length; i += 7) {
      for (let i = 0; i < copySchedules.length; i++) {
        const deleted = copySchedules[i].times.filter((time) => {
          return time.startDateTime.slice(12) !== targetDate.slice(12);
        });
        copySchedules[i].times = deleted;
      }
      updateSchedules(copySchedules);
    }

    let [newTimeSchedule] = deleteTargetTime; // Extract the filtered time schedule

    copySchedules[targetIndex].times = newTimeSchedule;
    updateSchedules(copySchedules);

    // Update the specific schedule with the new time schedule
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={toggleModal}
      footer={[]}
      width={"90%"}
      centered
    >
      {record.unit && (
        <Row>
          <Col xs={24} md={12}>
            <div className="guruCard">
              <Row>
                <Col xs={24} md={6}>
                  <div className="profileBox">
                    <img src={mediaBaseUrl + record.image} alt="avatar" />
                    {record.video && (
                      <Button
                        className="watchVidBtn"
                        onClick={() => {
                          toggleVidModal(!isVidModalVis);
                        }}
                      >
                        Watch Video
                        <img src={play} alt="play" />
                      </Button>
                    )}
                  </div>
                </Col>
                <Modal
                  visible={isVidModalVis}
                  onCancel={() => toggleVidModal(!isVidModalVis)}
                  footer={[]}
                  width={"60%"}
                  centered
                >
                  <Player src={mediaBaseUrl + record.video}>
                    <BigPlayButton position="center" />
                  </Player>
                </Modal>
                <Col xs={16} md={18}>
                  <div className="infoBox">
                    {/* <h2>{record.fullName}</h2> */}
                    <Input
                      type="text"
                      value={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                      }}
                      className="my-2"
                    />
                    <h5>{record.specializationList[0].categoryName}</h5>
                    {filledStar.map(() => {
                      return (
                        <img
                          src={filledStars}
                          alt="filledStar"
                          style={{ marginRight: "3px" }}
                        />
                      );
                    })}

                    {emptyStar.map(() => {
                      return (
                        <img
                          src={emptyStars}
                          alt="emptyStar"
                          style={{ marginRight: "3px" }}
                        />
                      );
                    })}
                    <span>({record.reviews.length})</span>

                    {/* <p dangerouslySetInnerHTML={{ __html: record.coachBio }} /> */}
                    <div className="my-2">
                      <CKEditor
                        key={isDataRecieved}
                        editor={ClassicEditor}
                        config={{
                          toolbar: ["bold", "italic", "numberedList"],
                          placeholder: "Keep it friendly ...",
                        }}
                        data={bio}
                        onReady={(editor) => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData();

                          setBio(data);
                        }}
                        onBlur={(event, editor) => {}}
                        onFocus={(event, editor) => {}}
                      />
                    </div>

                    <div>
                      {record.subspecializationList.map((subSpecialization) => {
                        return (
                          <span className="grayBox">
                            {subSpecialization.subCategoryName}
                          </span>
                        );
                      })}
                    </div>
                    <div className="pricePerHour my-2">
                      <span>USD</span>
                      <InputNumber
                        value={pricePerHour}
                        onChange={(price) => {
                          if (price > 1) {
                            updatePricePerHour(roundNearest1(price));
                          } else {
                            updatePricePerHour(1);
                          }
                        }}
                        min="1"
                        className="mx-1 text-center"
                        onKeyPress={validatePrcPerHour}
                      />
                      <span>/hr</span>
                    </div>

                    <div className="generalInfoBox">
                      <div>
                        {record.gender.length > 0 && <div>Gender</div>}
                        {record.birthDate && <div>Date of Birth</div>}
                        {/* {record.language.length > 0 && <div>Language</div>}
												{record.otherLanguages.length > 0 && <div>Other Languages</div>} */}
                        <div>Timezone</div>
                      </div>
                      <div>
                        <div>
                          {record.gender.length > 0 &&
                            record.gender[0].genderName}
                        </div>
                        <div>{moment(record.birthDate).format("YYYY")}</div>
                        {/* <div>{record.language[0].languageName}</div>
												<div>
													{record.otherLanguages.map((lang) => {
														return (
															<span style={{ marginRight: "5px" }}>
																{lang.languageName}
															</span>
														);
													})}
												</div> */}
                        <div>
                          {record.timeZone === "Africa/Addis_Ababa" &&
                          isSummerTime
                            ? "Africa/Cairo"
                            : record.timeZone}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-4">
                    <h4>Zoom Email</h4>
                    <div className="d-flex">
                      <Input
                        type="email"
                        value={zoomEmail}
                        onChange={(e) => {
                          updateZoomEmail(e.target.value);
                        }}
                      />
                      <Button
                        className="secondaryBtn mx-2"
                        onClick={handleSaveEmail}
                      >
                        Save
                      </Button>
                    </div>
                    {alert && (
                      <Alert
                        variant={alert.success ? "success" : "danger"}
                        className="text-center my-2"
                      >
                        {alert.message}
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          {/* Availability */}
          <Col xs={24} md={12}>
            <h4 className="mx-4">Set Availabilities</h4>
            <div className="d-flex align-items-center flex-column">
              <div className="my-2">
                <DatePicker
                  showNow={false}
                  format="YYYY-MM-DD HH:mm"
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                  showTime={{
                    defaultValue: moment("00:00", "HH:mm"),
                  }}
                  onChange={(dateTime) => {
                    updateSelectedDate(
                      moment(dateTime).tz(
                        isSummerTime ? "Africa/Addis_Ababa" : "Africe/Cairo"
                      )
                    );
                  }}
                  value={selectedDate}
                  className="mx-2"
                  allowClear={false}
                />
                <div>
                  Times are shown in Egypt time (
                  {isSummerTime ? "+03 " : "+02 "}
                  GMT)
                </div>
                {/* <TimezoneSelect
										style={{ width: "100%" }}
										value={selectedTimezone}
										onChange={setSelectedTimezone}
										timezones={{
											...allTimezones,
											"Africa/Addis_Ababa": "Cairo Summer Time",
										}}
									/> */}
                <Radio.Group
                  onChange={(e) => {
                    updateSessionPeriod(e.target.value);
                  }}
                  value={sessionPeriod}
                  size="large"
                  className="my-2 text-center"
                  style={{ width: "100%" }}
                >
                  {/* <Radio value={30} className="m-2">
										30 Minutes
									</Radio> */}
                  <Radio value={60}>1 Hour</Radio>
                </Radio.Group>
                <div className="d-flex justify-content-center align-items-center">
                  <span className="mx-2">Recursive Calendar</span>
                  <Switch
                    onChange={onRecursiveSwitchChange}
                    value={isRecursive}
                  />
                </div>
              </div>
              <Button
                className="secondaryBtn mx-2"
                disabled={!selectedDate}
                onClick={handleChooseTime}
              >
                Add
              </Button>
              <Checkbox
                checked={isFirstSessionFree}
                onChange={onFreeSessionChange}
                className="my-4 f-18"
              >
                Make First Discovery Session Free
              </Checkbox>
              {record.coachbankdetails && (
                <div>
                  <h4>Bank Details</h4>
                  <ul className="list-unstyled">
                    <li>
                      Beneficiary name:{" "}
                      {record.coachbankdetails.beneficiaryName}
                    </li>
                    <li>
                      Account number: {record.coachbankdetails.accountNumber}
                    </li>
                    <li>IBAN: {record.coachbankdetails.IBAN}</li>
                    <li>SWIFT code: {record.coachbankdetails.SWIFTCode}</li>
                    <li>Bank name: {record.coachbankdetails.bankName}</li>
                  </ul>
                </div>
              )}
              <div>
                <h4>Packages</h4>
                <div>
                  {record?.packages.map((packagee) => {
                    return (
                      <div className="my-2">
                        <div>Name: {packagee.name} </div>
                        <div>Description: {packagee.description} </div>
                        <div>
                          Sessions count: {packagee.sessionCount} Sessions
                        </div>
                        <div>price: {packagee.price} USD </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24}>
            <div className="guruCard calendar">
              <CalendarHeader
                getCurrentPages={(val) => setCurrentDates(val)}
                handleCalendarPages={(val) => {}}
              />
              <div>
                <Row>
                  <Col xs={2}></Col>
                  {currentSchedules.map((currentSchedule, index) => {
                    return (
                      <Col xs={4}>
                        <span key={index} id={index}>
                          {currentSchedule.map((time, index) => {
                            return (
                              <>
                                {time.booked ? (
                                  <></>
                                ) : (
                                  <div
                                    id={index}
                                    key={index}
                                    className={
                                      time.adminDiscount > 0
                                        ? "timeSlot discountSlot"
                                        : time.notSaved
                                        ? "border-red timeSlot"
                                        : "timeSlot"
                                    }
                                    onClick={() => {
                                      if (!time.notSaved) {
                                        if (time.booked) {
                                          toastError(
                                            "Sorry Session Time Already Booked"
                                          );
                                        } else {
                                          updateDiscountModalVisible(true);
                                          updateSelectedTimeSlot(time);
                                        }
                                      }
                                    }}
                                  >
                                    {moment(time.startDateTime)
                                      .tz(
                                        isSummerTime
                                          ? "Africa/Addis_Ababa"
                                          : "Africa/Cairo"
                                      )
                                      .format("HH:mm")}{" "}
                                    {time.isRecursive ? "(R)" : ""}
                                    {time.notSaved && (
                                      <CloseOutlined
                                        style={{
                                          position: "absolute",
                                          right: 10,
                                          color: "red",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          cursor: "pointer",
                                          fontWeight: "bold",
                                        }}
                                        onClick={(event) =>
                                          handleDeleteTime(event, time)
                                        }
                                      />
                                    )}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </span>
                      </Col>
                    );
                  })}
                  <Col xs={2}></Col>
                </Row>
                <Radio.Group
                  onChange={onRadioChange}
                  value={radioValue}
                  style={{ marginTop: "20px" }}
                  size="large"
                >
                  <Radio value={"active"}>Active</Radio>
                  <Radio value={"block"}>Block</Radio>
                </Radio.Group>
                <Input
                  type="number"
                  className="d-block mx-auto my-2"
                  style={{ width: "200px" }}
                  value={rank}
                  onChange={(e) => {
                    updateRank(e.target.value);
                  }}
                  placeholder={"Rank"}
                />
              </div>
            </div>
          </Col>
        </Row>
      )}
      <div className="btnContainer">
        <Button className="primaryBtn" onClick={() => toggleModal()}>
          Cancel
        </Button>
        <Button
          className="secondaryBtn"
          loading={loading}
          onClick={() => handleSubmit()}
        >
          Confirm
        </Button>
      </div>

      <ConfirmationModal
        isModalVisible={isConfModalVis}
        toggleAllModal={() => {
          toggleConfModal(!isConfModalVis);
          setTimeout(() => toggleModal(!isModalVisible), 20);
        }}
        toggleModal={() => toggleConfModal(!isConfModalVis)}
        text={`Are You Sure Blocking ${record.fullName} ?`}
        cancelText="Cancel"
        confirmText="Block"
        record={record}
      />
      {isDiscountModalVisible && (
        <DiscountModal
          schedulesArr={schedules}
          isDiscountModalVisible={isDiscountModalVisible}
          onCancel={() => {
            updateDiscountModalVisible(false);
          }}
          selectedTimeSlot={selectedTimeSlot}
          coachId={record._id}
          record={record}
          currentSchedules={[...currentSchedules]}
          updateCurrentSchedules={(values) => {
            setCurrentSchedules(values);
          }}
          schedules={[...schedules]}
          updateSchedules={(values) => {
            updateSchedules(values);
          }}
        />
      )}
    </Modal>
  );
}

export default GuruModal;
