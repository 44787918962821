import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Space } from "antd";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
import GuruModal from "../gurus/components/guruModal/guruModal";
import { Switch } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
//import SideMenu from "../common/components/sideMenu/sideMenu";
import { getAllUsers, ActivatedAndDeactivateUsers } from "./network";
import Grid from "../common/components/grid/grid";
import { mediaBaseUrl } from "../../services";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

function Gurus() {
  const [isModalVisible, toggleModal] = useState(false);
  const [record, setRecord] = useState({});
  const [gurus, setGurus] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            //			searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          //value={searchText[0]}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //	setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      render: (image) => {
        return (
          <div className="avatarContainer">
            <img src={mediaBaseUrl + image} alt="avatar" />
          </div>
        );
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          setRecord(record);
          toggleModal(!isModalVisible);
        },
      }),
    },
    {
      title: "Guru Name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: (a, b) => {
        if (a.fullName < b.fullName) return -1;
        else return 1;
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          setRecord(record);
          toggleModal(!isModalVisible);
        },
      }),
      ...getColumnSearchProps("fullName"),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "Year Of Birth",
      dataIndex: "birthDate",
      key: "birthDate",
      sorter: (a, b) =>
        new Date(a.birthDate).getTime() - new Date(b.birthDate).getTime(),
      onCell: (record, rowIndex) => ({
        onClick: () => {
          setRecord(record);
          toggleModal(!isModalVisible);
        },
      }),
      render: (birthDate) => {
        return <div>{moment(birthDate).format("YYYY")}</div>;
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => {
        if (a.email < b.email) return -1;
        else return 1;
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          setRecord(record);
          toggleModal(!isModalVisible);
        },
      }),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => {
        if (a.phone < b.phone) return -1;
        else return 1;
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          setRecord(record);
          toggleModal(!isModalVisible);
        },
      }),
    },
    {
      title: "Personal ID",
      dataIndex: "personalIdimage",
      key: "personalIdimage",
      render: (personalIdimage) => {
        return (
          <a
            href={mediaBaseUrl + personalIdimage}
            target="_blank"
            rel="noreferrer"
            className="d-block"
          >
            Personal ID
          </a>
        );
      },
    },
    {
      title: "Documents",
      dataIndex: "documents",
      key: "documents",
      render: (documents, _) => {
        let document =
          typeof _.document === "string" ? [_.document] : _.document;
        return document?.map((doc, docIndex) => (
          <a
            href={mediaBaseUrl + doc}
            target="_blank"
            rel="noreferrer"
            className="d-block"
          >
            Document
          </a>
        ));
      },
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      sorter: (a, b) => {
        if (a.rank < b.rank) return -1;
        else return 1;
      },
    },
    {
      title: "Block",
      dataIndex: "isActive",
      key: "isActive",
      render: (checked, _, index) => {
        return (
          <div>
            <Switch
              className={"switch"}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={!checked}
              onChange={(checked) =>
                ActivatedAndDeactivateUsers(
                  {
                    isActive: !checked,
                    id: _._id,
                    type: 2,
                  },
                  (success) => {
                    let gurusArr = [...gurus];
                    gurusArr.forEach((guru, guruIndex) => {
                      if (guru._id === _._id) {
                        gurusArr[guruIndex].isActive = !checked;
                      }
                    });
                    setGurus(gurusArr);
                  },
                  (fail) => console.log(fail)
                )
              }
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAllUsers(
      (success) => {
        setGurus(success.users);
      },
      (fail) => {}
    );
  }, []);

  return (
    <div className="gurus">
      <Navbar />
      <Header parent="Gurus" />
      <Row>
        <Col xs={24}>
          <Grid dataSource={gurus} columns={columns} parent="Gurus" />
        </Col>
      </Row>
      {isModalVisible && (
        <GuruModal
          isModalVisible={isModalVisible}
          toggleModal={() => {
            getAllUsers(
              (success) => {
                setGurus(success.users);
                toggleModal(!isModalVisible);
              },
              (fail) => {}
            );
          }}
          record={record}
        />
      )}
    </div>
  );
}

export default Gurus;
