import React, { useState, useEffect } from "react";
import { Button, Input, Select } from "antd";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { getAllUsers } from "../../../gurus/network";
import { createPromocode } from "../../network";
import { toastError } from "../../../../helpers/toast";
import "./PromoCodeForEvent.css";
import { getAllEvents } from "../../../Events/network";

function PromoCodeForEvent({
  callGetPromocodes,
  dataSource,
  salesPersonsData,
}) {
  const [percent, updatePercent] = useState(null);
  const [guruId, updateGuruId] = useState(null);
  const [options, updateOptions] = useState([]);
  const [gurusList, updateGurusList] = useState([]);
  const [eventsList, updateEventsList] = useState([]);
  const [eventId, updateEventId] = useState(null);
  const [promocode, updatePromocode] = useState(null);
  const [selectedSalesPerson, updateSelectedSalesPerson] = useState(null);
  const { Option } = Select;

  function handleEventChange(value) {
    updateEventId(value);
  }

  useEffect(() => {
    let data = [];
    getAllUsers(
      (success) => {
        updateGurusList(success.users);
        success.users.forEach((user) => {
          data.push({
            name: user.fullName,
            value: user._id,
          });
        });
        updateOptions(data);
      },
      (fail) => {
        console.log(fail);
      }
    );
  }, []);

  // useEffect(() => {
  //   const data = {
  //     timeZone: localStorage.getItem("timeZone"),
  //   };
  //   getAllEvents(
  //     data,
  //     (success) => {
  //       console.log(
  //         "🚀 ~ file: PromoCodeForEvent.js:56 ~ useEffect ~ success:",
  //         success
  //       );
  //     },
  //     (failed) => {
  //       console.log(
  //         "🚀 ~ file: PromoCodeForEvent.js:60 ~ useEffect ~ failed:",
  //         failed
  //       );
  //     }
  //   );
  // }, []);

  // Waitiiiiiiiiingggggggg
  const handleGetEvents = (guruId) => {
    const guruDetails = gurusList.filter((guru) => guru._id === guruId);
    const [details] = guruDetails;
    console.log(details);
    // updateEventsList((preState) => [{ ...preState, ev }]);
    // updateEventsList(guruDetails[0].events);
  };

  const handleGeneratePromocode = () => {
    const isNotUniquePromocode = dataSource.some(
      (promoCode) => promoCode.coupon === promocode
    );
    if (isNotUniquePromocode) {
      toastError("Promocode is used before!");
    } else {
      if (percent && eventId) {
        if (percent) {
          if (percent >= 90 && percent <= 99) {
            toastError("Percentage between 90 and 99 are not allowed!");
          } else {
            let data = {
              discountPercentage: percent > 100 ? 100 : percent,
              couponType: 4,
              coachId: guruId,
              eventId: eventId,
              promocode,
              isSingleUsage: false,
              salesPersonId: selectedSalesPerson,
            };
            createPromocode(
              data,
              (success) => {
                if (success.code === 1) callGetPromocodes();
              },
              (fail) => {
                console.log(fail);
              }
            );
          }
        }
      } else {
        toastError("Please provide a valid Percent or select a Event!");
      }
    }
  };

  const handleChangeSalesPerson = (salesPersonId) => {
    updateSelectedSalesPerson(salesPersonId);
  };
  return (
    <div className="promocodeForEvent m-4 d-flex flex-wrap justify-content-center">
      <Input
        type={"number"}
        max={100}
        min={1}
        placeholder="Percent %"
        className="percentBox text-center m-2"
        value={percent}
        onChange={(e) => {
          updatePercent(e.target.value);
        }}
      />
      <div className="my-2">
        <SelectSearch
          style={{ width: 200 }}
          options={options}
          search
          value={guruId}
          placeholder="Select a guru"
          filterOptions={fuzzySearch}
          onChange={(guruId) => {
            console.log(guruId);
            updateGuruId(guruId);
            handleGetEvents(guruId);
            updateEventId(null);
          }}
        />
      </div>

      {/* Events */}
      <Select
        style={{ width: 200 }}
        onChange={handleEventChange}
        className="m-2"
        disabled={!eventsList.length}
        placeholder={"Select a event"}
      >
        {eventsList.map((evente) => {
          return (
            <Option value={evente._id} key={evente._id}>
              {evente.name}
            </Option>
          );
        })}
      </Select>
      <Select
        style={{ width: 300 }}
        onChange={handleChangeSalesPerson}
        className="m-2"
        disabled={!eventsList.length}
        placeholder={"Select a sales person (Optional)"}
      >
        {salesPersonsData.map((person) => {
          return (
            <Option value={person.salesPersonId} key={person.salesPersonId}>
              {person.name}
            </Option>
          );
        })}
      </Select>
      <Input
        type={"text"}
        placeholder="Promocode (optional)"
        className="text-center m-2"
        value={promocode}
        style={{ width: "200px" }}
        onChange={(e) => {
          updatePromocode(e.target.value);
        }}
      />

      <Button className="btn m-2" onClick={handleGeneratePromocode}>
        Generate
      </Button>
    </div>
  );
}

export default PromoCodeForEvent;
