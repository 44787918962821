import { getResource, postResource } from "../../services";

export const getAllUsers = (onSuccess, onFail) => {
	const path = "admin/GetAllUsers?type=1";
	getResource(path, onSuccess, onFail);
};

export const ActivatedAndDeactivateUsers = (body, onSuccess, onFail) => {
	const path = "admin/ActivatedAndDeactivateUsers";
	postResource(path, body, onSuccess, onFail);
};
