import React from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { logout } from "../../../../network";
import { connect } from "react-redux";
import Logo from "../../../../assets/icons/logo.js";
import { AuthActions } from "../../../../Store/Actions/AuthActions";
import DropDownArrow from "../../../../assets/icons/dropDownArrow.svg";
import "./navbar.css";
function navbar(props) {
	const menu = (
		<Menu>
			{props.auth.authData ? (
				<Menu.Item
					onClick={() => {
						logout({ adminId: props.auth.authData._id }, (success) => {
							props.AuthActions(0);
						});
					}}
				>
					Log out
				</Menu.Item>
			) : (
				<span>
					<Menu.Item>Signin</Menu.Item>
					<Menu.Item>Sign up</Menu.Item>
				</span>
			)}
		</Menu>
	);
	return (
		<nav>
			<Link to="/">
				<Logo height={"30"} width={"70"} />
			</Link>
			{!props.hideMenu && (
				<Dropdown overlay={menu} placement="bottomRight" arrow>
					<img
						src={DropDownArrow}
						alt="DropDownArrow"
						className="dropDownArrow"
					/>
				</Dropdown>
			)}
		</nav>
	);
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		AuthActions: (auth) => dispatch(AuthActions(auth)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(navbar);
