import React, { Component } from "react";
import Navbar from "../common/components/navbar/navbar";
import { Alert } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { AddNewPassword } from "./network";
import "./ChangePassword.css";
class ChangePassword extends Component {
	state = {
		password: "",
		confirmPassword: "",
		errMsg: "",
		id: this.props.match.params.id,
		successMsg: "",
		inputType: true,
		redirectState: false,
	};
	onChange = (e) => {
		this.setState({ [e.target.id]: e.target.value }, () => {
			var reg =
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#^*?&])[A-Za-z\d@$!#^%*?&]{8,}$/;
			var test = reg.test(this.state.password);
			if (
				this.state.password.length !== 0 &&
				this.state.confirmPassword.length !== 0
			) {
				if (test) {
					if (this.state.password !== this.state.confirmPassword) {
						this.setState({ errMsg: "Passwords don't match" });
					} else {
						this.setState({ errMsg: "" });
					}
				} else {
					this.setState({ errMsg: "Password is weak" });
				}
			}
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();

		if (
			this.state.password === this.state.confirmPassword &&
			this.state.errMsg.length === 0 &&
			this.state.password.length > 0
		) {
			let body = {
				id: this.state.id,
				password: this.state.password,
			};
			AddNewPassword(
				body,
				(success) => {
					if (success.status === 1) {
						this.setState({ redirectState: true });
					}
				},
				(fail) => {}
			);
		}
	};
	render(props) {
		if (this.state.redirectState) return <Redirect to="/" />;
		return (
			<div className="enterNewPassword">
				<Navbar hideMenu={true} />
				<form onSubmit={this.handleSubmit}>
					<div className="inputBox">
						<label>Password</label>
						<div className="inputContainer">
							<input
								type={this.state.inputType ? "password" : "text"}
								value={this.state.password}
								onChange={this.onChange}
								className="form-control"
								id="password"
							/>
							<span
								onClick={() =>
									this.setState({ inputType: !this.state.inputType })
								}
							>
								{/* <SigninEye /> */}
							</span>
						</div>
						<label>Confirm Password</label>
						<div className="inputContainer">
							<input
								type={this.state.inputType ? "password" : "text"}
								value={this.state.confirmPassword}
								onChange={this.onChange}
								className="form-control"
								id="confirmPassword"
							/>
							<span
								onClick={() =>
									this.setState({ inputType: !this.state.inputType })
								}
							>
								{/* <SigninEye /> */}
							</span>
						</div>
						{this.state.errMsg.length > 0 && (
							<Alert className="mt-2 show" variant="danger">
								{this.state.errMsg}
							</Alert>
						)}
						{this.state.successMsg.length > 0 && (
							<Alert className="mt-2 show" variant="success">
								{this.state.successMsg}
							</Alert>
						)}
					</div>
					<div className="btnContainer">
						<button className="btn">
							<Link to="/">Close</Link>
						</button>
						<button type="submit" className="btn">
							Reset
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default ChangePassword;
