import { getResource, postResource } from "../../services";

export const getLanguages = (onSuccess, onFail) => {
	const path = "account/languageList";
	getResource(path, onSuccess, onFail);
};
export const AddLanguage = (body, onSuccess, onFail) => {
	const path = "account/addLanguage";
	postResource(path, body, onSuccess, onFail);
};
export const getCurrency = (onSuccess, onFail) => {
	const path = "account/unitList";
	getResource(path, onSuccess, onFail);
};

export const addUnit = (body, onSuccess, onFail) => {
	const path = "account/addUnit";
	postResource(path, body, onSuccess, onFail);
};

export const getPrefix = (onSuccess, onFail) => {
	const path = "account/prefixList";
	getResource(path, onSuccess, onFail);
};

export const AddPrefix = (body, onSuccess, onFail) => {
	const path = "account/AddPrefix";
	postResource(path, body, onSuccess, onFail);
};

export const getCategoryListByMainCategoryId = (body, onSuccess, onFail) => {
	const path = "account/CategoryListByMainCategoryId";
	postResource(path, body, onSuccess, onFail);
};

export const getSubCategoryListByCategoryId = (body, onSuccess, onFail) => {
	const path = "account/SubCategoryListByCategoryId";
	postResource(path, body, onSuccess, onFail);
};

export const getMainCategoryList = (onSuccess, onFail) => {
	const path = "user/AllCategoryList";
	getResource(path, onSuccess, onFail);
};

export const addCategory = (body, onSuccess, onFail) => {
	const path = "account/addCategory";
	postResource(path, body, onSuccess, onFail);
};

export const addSubCategory = (body, onSuccess, onFail) => {
	const path = "account/addSubCategory";
	postResource(path, body, onSuccess, onFail);
};

export const GetAllListAndLastUpdatedDate = (onSuccess, onFail) => {
	const path = "admin/GetAllListAndLastUpdatedDate";
	getResource(path, onSuccess, onFail);
};
