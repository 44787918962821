import React from "react";
import { Modal, Col, Row, Button } from "antd";
import clock from "../../../../assets/icons/clock.svg";
import { mediaBaseUrl } from "../../../../services";
import { AdminAcceptOrDeclineComplain } from "../../network";
import "./reportedCoachModal.css";
function ReportedCoachModal({ isModalVisible, toggleModal, record, refresh }) {
  const handleAdminAcceptOrDeclineComplain = (accept) => {
    let body = {
      accept,
      reviewId: record.reviews._id,
      coachId: record._id,
    };
    AdminAcceptOrDeclineComplain(
      body,
      (success) => {
        if (success.code === 1) {
          refresh(true);
          toggleModal();
        }
      },
      (fail) => {}
    );
  };
  const initials = record?.user[0]?.firstName[0] + record?.user[0]?.lastName[0];
  return (
    <Modal
      visible={isModalVisible}
      onCancel={toggleModal}
      footer={[]}
      width={"90%"}
      centered
      className="reportedCoachModal"
    >
      {record && (
        <div>
          <Row>
            <Col xs={24} md={12}>
              <div className="coachClientCard">
                <div>
                  <label>Guru</label>
                  <div>
                    <img
                      src={mediaBaseUrl + record?.image}
                      alt="avatar"
                      className="avatarLg"
                    />
                    <div style={{ wordBreak: "break-all" }}>
                      <div>{record?.fullName}</div>
                      <div>{record?.email}</div>
                      <div>{record?.phone}</div>
                    </div>
                  </div>
                </div>
                <div>
                  <label>Client</label>
                  <div>
                    <span>
                      {record?.user[0]?.image.length > 0 ? (
                        <img
                          src={mediaBaseUrl + record?.user[0]?.image}
                          alt="avatar"
                          className="avatarLg"
                        />
                      ) : (
                        <div className="avatarLg d-flex justify-content-center align-items-center">
                          <>{initials}</>
                        </div>
                      )}
                    </span>

                    <div style={{ wordBreak: "break-all" }}>
                      <div>{record?.user[0]?.fullName}</div>
                      <div>{record?.user[0]?.email}</div>
                      <div>{record?.user[0]?.phone}</div>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="timeBar">
                  <span>
                    <img src={clock} alt="clock" />
                    <span>{record?.session[0]?.sessionFullDate}</span>
                  </span>
                  <span>
                    {record?.unit[0]?.unitName} {record?.session[0]?.price}
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="clientComplaint">
                <label>Client Complaint</label>
                <p>{record?.reviews?.report}</p>
              </div>
            </Col>
          </Row>
          <div className="btnContainer">
            <Button
              className="primaryBtn"
              onClick={() => handleAdminAcceptOrDeclineComplain(true)}
            >
              Ignore
            </Button>
            <Button
              className="secondaryBtn"
              onClick={() => handleAdminAcceptOrDeclineComplain(false)}
            >
              Freeze
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default ReportedCoachModal;
