import React from "react";
import { Row, Col } from "antd";
function Stats() {
	return (
		<div>
			<Row>
				<Col xs={6}>
					<div className="statsContainer">1</div>
				</Col>
				<Col xs={6}>
					<div className="statsContainer">1</div>
				</Col>
				<Col xs={6}>
					<div className="statsContainer">1</div>
				</Col>
				<Col xs={6}>
					<div className="statsContainer">1</div>
				</Col>
			</Row>
		</div>
	);
}

export default Stats;
