import React, { useState } from "react";
import { Button, Input, Select } from "antd";
import { createPromocode } from "../../network";
import { Checkbox } from "antd";
import { toastError, toastSuccess } from "../../../../helpers/toast";
import "./GeneralPromocode.css";
function GeneralPromocode({ callGetPromocodes, dataSource, salesPersonsData }) {
	const [percent, updatePercent] = useState(null);
	const [promocode, updatePromocode] = useState(null);
	const [isUsedMultipleTimes, updateIsUsedMultipleTimes] = useState(false);
	const [selectedSalesPerson, updateSelectedSalesPerson] = useState(null);
	const [matchingText, updateMatchingText] = useState();
	const { Option } = Select;

	const onIsUsedMultipleTimesChange = (e) => {
		updateIsUsedMultipleTimes(e.target.checked);
	};

	const handleGeneratePromocode = () => {
		const isNotUniquePromocode = dataSource.some(
			(promoCode) => promoCode.coupon === promocode
		);
		if (isNotUniquePromocode) {
			toastError("Promocode is used before!");
		} else {
			if (percent) {
				if (percent >= 90 && percent <= 99) {
					toastError("Percentage between 90 and 99 are not allowed!");
				} else {
					let data = {
						discountPercentage: percent > 100 ? 100 : percent,
						couponType: 1,
						promocode: promocode ? promocode : null,
						isSingleUsage: !isUsedMultipleTimes,
						salesPersonId: selectedSalesPerson,
						matchingText: matchingText ? matchingText.trim() : null,
					};
					createPromocode(
						data,
						(success) => {
							if (success.code === 1) {
								callGetPromocodes();
								toastSuccess(success.message);
								updatePromocode(null);
								updatePercent(null);
							}
						},
						(fail) => {
							console.log(fail);
						}
					);
				}
			} else {
				toastError("Please provide a valid Percent!");
			}
		}
	};
	const handleChangeSalesPerson = (salesPersonId) => {
		updateSelectedSalesPerson(salesPersonId);
	};
	return (
		<div className="generalPromocode m-4 d-flex justify-content-center align-items-center">
			<Input
				type={"number"}
				max={100}
				min={1}
				placeholder="Percent %"
				className="percentBox text-center"
				value={percent}
				onChange={(e) => {
					updatePercent(e.target.value);
				}}
			/>
			<Select
				style={{ width: 300 }}
				onChange={handleChangeSalesPerson}
				value={selectedSalesPerson}
				className="mx-2"
				placeholder={"Select a sales person (Optional)"}>
				{salesPersonsData.map((person) => {
					return (
						<Option value={person.salesPersonId} key={person.salesPersonId}>
							{person.name}
						</Option>
					);
				})}
			</Select>
			<Input
				type={"text"}
				placeholder="Promocode (optional)"
				className="text-center mx-2"
				value={promocode}
				style={{ width: "200px" }}
				onChange={(e) => {
					updatePromocode(e.target.value);
				}}
			/>
			<Input
				type={"text"}
				placeholder="Client's Matching domains"
				className="text-center m-2"
				value={matchingText}
				style={{ width: "200px" }}
				onChange={(e) => {
					updateMatchingText(e.target.value);
				}}
			/>
			<Checkbox onChange={onIsUsedMultipleTimesChange}>
				Used Multiple Times
			</Checkbox>
			<Button className="btn mx-2" onClick={handleGeneratePromocode}>
				Generate
			</Button>
		</div>
	);
}

export default GeneralPromocode;
