import { getResource, postResource, deleteResource } from "../../services";

export const getGurusPackages = (data, onSuccess, onFail) => {
	const { guruId } = data;
	const path = `package/coachPackages?coachId=${guruId}`;
	getResource(path, onSuccess, onFail);
};
export const addEditPackage = (data, onSuccess, onFail) => {
	const path = `package/coachPackage`;
	postResource(path, data, onSuccess, onFail);
};
export const deletePackage = (data, onSuccess, onFail) => {
	const { packageId } = data;
	const path = `package/deactivateCoachPackage?packageId=${packageId}`;
	deleteResource(data, path, onSuccess, onFail);
};
export const ChangePackStatus = (data, onSuccess, onFail) => {
	const path = `/`;
	postResource(data, path, onSuccess, onFail);
};
