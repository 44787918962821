import { getResource, postResource } from "../../services";

export const getPromocodes = (onSuccess, onFail) => {
	const path = "account/Coupons ";
	getResource(path, onSuccess, onFail);
};
export const createPromocode = (body, onSuccess, onFail) => {
	const path = "account/Coupon";
	postResource(path, body, onSuccess, onFail);
};
export const clearPromocode = (body, onSuccess, onFail) => {
	const path = "account/DeactivateCoupon";
	postResource(path, body, onSuccess, onFail);
};

export const promoCodeToggleStateAndUsage = (body, onSuccess, onFail) => {
	const path = "account/toggleStateAndUsage";
	postResource(path, body, onSuccess, onFail);
};
export const getSalesPersonData = (onSuccess, onFail) => {
	const path = "salesPerson/salesPersonPromoCodes";
	getResource(path, onSuccess, onFail);
};
