import { postResource, getResource } from "../../services";

export const getCoachsDiscountPerDay = (onSuccess, onFail) => {
	const path = "account/CoachesDiscountPerDay";
	getResource(path, onSuccess, onFail);
};
export const AddCoachesDisCountPerDay = (body, onSuccess, onFail) => {
	const path = "account/AddCoachesDisCountPerDay";
	postResource(path, body, onSuccess, onFail);
};
