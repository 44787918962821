import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
// import logger from "redux-logger";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import RootReducer from "./Store/Reducers/RootReducer";
import "../node_modules/video-react/dist/video-react.css"; // import css
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

// const middlwares = [logger];
// const store = createStore(RootReducer, applyMiddleware(...middlwares));
const store = createStore(RootReducer);
const persistor = persistStore(store);

// store.subscribe(() => {
// 	console.log("updated state", store.getState());
// });

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
