export const AuthActions = (auth) => {
	if (auth === 0) {
		return {
			type: "LOG_OUT",
			auth,
		};
	} else {
		return {
			type: "USER_LOGIN",
			auth,
		};
	}
};
