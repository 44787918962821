import React from "react";
import { Row, Col } from "antd";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
import SideMenu from "../common/components/sideMenu/sideMenu";
import Stats from "./components/stats/stats";
function Dashboard() {
	return (
		<div>
			<Navbar />
			<Header parent="Dashboard" />
			<Row>
				<Col xs={4}>
					<SideMenu current="Dashboard" />
				</Col>
				<Col xs={20}>
					<Stats />
				</Col>
			</Row>
		</div>
	);
}

export default Dashboard;
