import { getResource, postResource } from "../../services";

export const getAllContactMessages = (onSuccess, onFail) => {
	const path = "admin/getAllContactMessages";
	getResource(path, onSuccess, onFail);
};

export const ContactAdminResponse = (body, onSuccess, onFail) => {
	const path = "admin/ContactAdminResponse";
	postResource(path, body, onSuccess, onFail);
};
