import React from "react";
import Logo from "../../../../assets/icons/logo.js";
import "./logoPage.css";
function logoPage() {
	return (
		<div className="logoPage">
			<span>
				<Logo width={"100%"} height={"171"} />
			</span>
		</div>
	);
}

export default logoPage;
