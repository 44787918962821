import { postResource, getResource } from "../../services";

export const Confirmemail = (body, onSuccess, onFail) => {
	const path = "admin/AdminForgetPassword";
	postResource(path, body, onSuccess, onFail);
};
export const getConfirmemail = (onSuccess, onFail) => {
	const path = "admin/AdminForgotPassword";
	getResource(path, onSuccess, onFail);
};
