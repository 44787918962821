import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Space } from "antd";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
import Grid from "../common/components/grid/grid";
//import SideMenu from "../common/components/sideMenu/sideMenu";
import firebase from "firebase/app";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import "firebase/messaging";
import "firebase/firestore";
import "firebase/firebase-database";
import "firebase/auth";

firebase.initializeApp({
	apiKey: "AIzaSyCESDBNFfFZ7poCpd550hjuF2DKhUfBclQ",
	authDomain: "welo-e1758.firebaseapp.com",
	databaseURL: "https://welo-e1758-default-rtdb.firebaseio.com",
	projectId: "welo-e1758",
	storageBucket: "welo-e1758.appspot.com",
	messagingSenderId: "301368606527",
	appId: "1:301368606527:web:ac92d72e8184991b715d8a",
	measurementId: "G-SE0RL59NP0",
});

function LiveChat(props) {
	const [dataSource, updateDataSource] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						//			searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					//value={searchText[0]}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
				</Space>
			</div>
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
					.toString()
					.toLowerCase()
					.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				//	setTimeout(() => searchInput.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const getFBUsers = () => {
		const db = firebase.database();
		var usersRef = db.ref("users");
		usersRef.on("value", (snapshot) => {
			if (snapshot.val()) {
				const values = Object.values(snapshot.val());
				updateDataSource(values);
			}
		});
	};
	useEffect(() => {
		getFBUsers();
		// eslint-disable-next-line
	}, []);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => {
				if (a.name < b.name) return -1;
				else return 1;
			},
			...getColumnSearchProps("name"),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
			render: (name, record) => {
				return (
					<div>
						{record.isAdminRead === false && (
							<span className="isRepliedDot"></span>
						)}
						{name}
					</div>
				);
			},
		},
		{
			title: "User Type",
			dataIndex: "userType",
			key: "userType",
			sorter: (a, b) => {
				if (a.userType < b.userType) return -1;
				else return 1;
			},
		},
		{
			title: "Last Date",
			dataIndex: "lastDate",
			key: "lastDate",
			sorter: (a, b) =>
				new Date(a.lastDate).getTime() - new Date(b.lastDate).getTime(),

			render: (lastDate) => {
				return <div>{moment(lastDate).format("MMMM Do YYYY, h:mm a Z")}</div>;
			},
			defaultSortOrder: "descend",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			sorter: (a, b) => {
				if (a.name < b.name) return -1;
				else return 1;
			},
		},
		{
			title: "Phone",
			dataIndex: "phone",
			key: "phone",
			sorter: (a, b) => {
				if (a.phone < b.phone) return -1;
				else return 1;
			},
		},
		{
			title: "Message",
			dataIndex: "message",
			key: "message",
			sorter: (a, b) => {
				if (a.message < b.message) return -1;
				else return 1;
			},
		},
	];

	// if (!record.isAdminRead) {
	// 	updateNewMsgNotification(true);
	// }

	return (
		<div>
			<Navbar />
			<Header parent="Live Chat" />
			<Row>
				{/* <Col xs={4}>
					<SideMenu current="Live Chat" />
				</Col> */}
				<Col xs={24}>
					<Grid
						dataSource={dataSource}
						columns={columns}
						parent="LiveChat"
						state={props.location.state}
					/>
				</Col>
			</Row>
		</div>
	);
}

export default LiveChat;
