import React from "react";
import { Modal, Col, Row } from "antd";
import { Cascader } from "antd";
import { UpdateSessionStatus } from "../../network";
import { mediaBaseUrl } from "../../../../services";
import moment from "moment";
import "./sessionModal.css";
import { isSummerTime } from "../../../../App";

function sessionModal({
  isModalVisible,
  toggleModal,
  record,
  serverTime,
  updateSessions,
}) {
  const initials =
    record.clientFirstName &&
    Object.values(record.clientFirstName[0][0]) +
      Object.values(record.clientLastName[0][0]);
  var serverDate = new Date(serverTime);
  var sessionDate = new Date(record.sessionEnd);
  const sessionStatus =
    serverDate.getTime() > sessionDate.getTime() ? "Completed" : "Incompleted";

  const options = [
    {
      value: "Confirmed",
      label: "Confirmed",
    },
    {
      value: "Cancelled",
      label: "Cancelled",
    },
    {
      value: "Reschedule",
      label: "Reschedule",
    },
    {
      value: "No Answer",
      label: "No Answer",
    },
    {
      value: "Paid out",
      label: "Paid out",
    },
  ];

  function onChange(value) {
    let body = {
      id: record._id,
      response: value[0],
    };

    UpdateSessionStatus(
      body,
      (success) => {
        updateSessions();
      },
      (fail) => {}
    );
  }

  return (
    <Modal
      visible={isModalVisible}
      onCancel={toggleModal}
      footer={[]}
      width={"80%"}
      centered
    >
      {record.status && (
        <Row>
          <Col md={12} xs={24}>
            <div className="modalBoxs">
              <label>Guru Informations</label>
              <div className="boxContent flex">
                <img src={mediaBaseUrl + record.profileImg[0]} alt="avatar" />
                <span>
                  <div>{record.guru}</div>
                  <div>{record.sessionType}</div>
                  <div>{record.guruPhone}</div>
                </span>
              </div>
            </div>

            <div className="modalBoxs">
              <label>Client Informations</label>
              <div className="boxContent flex">
                {record.clientImg[0].length > 0 && false ? (
                  <img
                    src={mediaBaseUrl + record.clientImg[0]}
                    alt="avatar"
                    className="avatar"
                  />
                ) : (
                  <span className="initials">
                    <span>{initials}</span>
                  </span>
                )}
                <span>
                  <div>{record.client}</div>
                  <div>{record.clientPhone}</div>
                </span>
              </div>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className="modalBoxs">
              <label>Session Informations</label>
              <div className="boxContent">
                <Row>
                  <Col xs={12}>
                    <div>
                      {record.sessionTopic.length === 0
                        ? "No Session Topic"
                        : record.sessionTopic}
                    </div>
                    <div>{record.paymentNumber}</div>
                    <div>{record.period === 30 ? "30 mins" : "1 hr"} </div>
                    {record.coupon && <div>PromoCode: {record.coupon}</div>}
                    <div>Status: {sessionStatus}</div>

                    <Cascader
                      options={options}
                      onChange={onChange}
                      placeholder={"Response"}
                      style={{ width: "90%" }}
                    />
                  </Col>
                  <Col xs={12}>
                    <div>{record.sessionType}</div>
                    <div>{record.status}</div>
                    {/* <div>
											{moment(record.date).format("DD/MM/YYYY")}{" "}
											{moment(record.time).format("HH:mm")}
										</div> */}
                    <div>
                      {moment(record.date).format("DD-MM-YYYY")}{" "}
                      {moment(record.time)
                        .tz(
                          isSummerTime ? "Africa/Addis_Ababa" : "Africa/Cairo"
                        )
                        .format("hh:mm a")}
                      (Egypt Time)
                    </div>
                    <div>
                      Price: {record.price}
                      {record.currency}
                    </div>
                    <div>Session Id: {record._id}</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
}

export default sessionModal;
