import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { getEventById } from "./network";
import { mediaBaseUrl } from "../../services";
import "./EventModal.css";
function EventDetailsModal({ isModalVisible, onCancel }) {
  const [dataSource, updateDataSource] = useState([]);

  const columns = [
    {
      title: "FullName",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Seats",
      dataIndex: "noOfSeats",
      key: "noOfSeats",
    },
  ];

  useEffect(() => {
    if (isModalVisible?.record?.eventId) {
      const data = {
        eventId: isModalVisible?.record?.eventId,
      };
      getEventById(
        data,
        (success) => {
          updateDataSource(success.data?.users);
        },
        (fail) => {}
      );
    }
  }, [isModalVisible?.record?.eventId]);

  return (
    <Modal
      visible={isModalVisible}
      onCancel={onCancel}
      footer={[]}
      width={"90%"}
      className="event-modal"
      centered
    >
      <h4 className="text-center my-4">Event Booking Details</h4>
      <Table dataSource={dataSource} columns={columns} />
    </Modal>
  );
}

export default EventDetailsModal;
