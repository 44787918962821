import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "antd";
import {
	getLanguages,
	AddLanguage,
	getCurrency,
	addUnit,
	getPrefix,
	AddPrefix,
	getCategoryListByMainCategoryId,
	getSubCategoryListByCategoryId,
	getMainCategoryList,
	addCategory,
	addSubCategory,
} from "../../network";
import EditModal from "../EditModal/EditModal";
import Pen from "../../../../assets/icons/pen.svg";
import Delete from "../../../../assets/icons/delete.svg";
import "./websiteWordsModal.css";
import { toastSuccess } from "../../../../helpers/toast";
function WebsiteWordsModal({ isModalVisible, toggleModal, record }) {
	const [input, setInput] = useState("");
	const [dataList, setDataList] = useState([]);
	const [subCategoryList, handleSubCategoryList] = useState([]);
	const [mainCategory, setMainCategory] = useState([]);
	const [mainCategoryId, setMainCategoryId] = useState(null);
	const [specialityInput, setSpecialityInput] = useState("");
	const [subSpecialityInput, setSubSpecialityInput] = useState("");
	const [subCategoryValue, setSubCategoryValue] = useState("");
	const [isEditModalVisible, toggleEditModal] = useState(false);
	const [recordId, setRecordId] = useState("");
	const [recordName, setRecordName] = useState("");
	const [refresh, setRefresh] = useState(false);
	const [recordType, setRecordType] = useState(null);
	const handleSubmit = () => {
		toggleModal();
		toastSuccess("Saved Successfully");
	};

	const addNewLanguage = () => {
		let body = {
			isActive: true,
			languageName: input,
		};
		AddLanguage(
			body,
			(success) => {
				if (success === "Added successfully") {
					getLanguages(
						(success) => {
							setDataList(success.languages);
							setInput("");
						},
						(fail) => {}
					);
				}
			},
			(fail) => {}
		);
	};

	const addNewUnit = () => {
		let body = {
			isActive: true,
			unitName: input,
		};
		addUnit(
			body,
			(success) => {
				if (success === "Added successfully") {
					getCurrency(
						(success) => {
							setDataList(success.units);
							setInput("");
						},
						(fail) => {}
					);
				}
			},
			(fail) => {}
		);
	};

	const addnewPrefix = () => {
		let body = {
			name: input,
		};
		AddPrefix(
			body,
			(success) => {
				if (success === "Added successfully") {
					getPrefix(
						(success) => {
							setDataList(success.prefixes);
							setInput("");
						},
						(fail) => {}
					);
				}
			},
			(fail) => {}
		);
	};
	const addNewSpeciality = () => {
		if (mainCategoryId && specialityInput.length > 0) {
			let body = {
				isActive: true,
				categoryName: specialityInput,
				mainCategoryId: mainCategoryId,
			};
			addCategory(
				body,
				(success) => {
					if (success === "Added successfully") {
						setSpecialityInput("");
						getCategoryListByMainCategoryId(
							{
								mainCategoryId: mainCategoryId,
							},
							(success) => {
								setDataList(success);
								toastSuccess("Added Successfully");
							},
							() => {}
						);
					}
				},
				(fail) => {}
			);
		}
	};

	const addNewSubCategory = () => {
		if (subCategoryValue && subSpecialityInput.length > 0) {
			let body = {
				category: subCategoryValue,
				subCategoryName: subSpecialityInput,
			};
			addSubCategory(
				body,
				(success) => {
					toastSuccess(success);
					if (success === "Added successfully") {
						setSubSpecialityInput("");
						getSubCategoryListByCategoryId(
							{ categoryId: subCategoryValue },
							(success) => {
								handleSubCategoryList(success);
							},
							() => {}
						);
					}
				},
				(fail) => {}
			);
		}
	};

	const handleDelete = (type, name, id) => {
		switch (type) {
			case "Languages": {
				let body = {
					isActive: false,
					languageName: name,
					edit: true,
					id: id,
				};
				AddLanguage(
					body,
					(success) => {
						if (success === "Added successfully") {
							getLanguages(
								(success) => {
									setDataList(success.languages);
								},
								(fail) => {}
							);
						}
					},
					(fail) => {}
				);
				break;
			}
			case "Currency": {
				let body = {
					unitName: name,
					isActive: false,
					id: id,
					edit: true,
				};
				addUnit(
					body,
					(success) => {
						if (success === "Added successfully") {
							getCurrency(
								(success) => {
									setDataList(success.units);
								},
								(fail) => {}
							);
						}
					},
					(fail) => {}
				);
				break;
			}

			case "Prefix": {
				let body = {
					name: name,
					edit: true,
					id: id,
					isActive: false,
				};
				AddPrefix(
					body,
					(success) => {
						if (success === "Added successfully") {
							getPrefix(
								(success) => {
									setDataList(success.prefixes);
								},
								(fail) => {}
							);
						}
					},
					(fail) => {}
				);
				break;
			}
			case "Speciality": {
				let body = {
					isActive: false,
					categoryName: name,
					mainCategoryId: mainCategoryId,
					id: id,
					edit: true,
				};
				addCategory(
					body,
					(success) => {
						if (success === "Added successfully") {
							getCategoryListByMainCategoryId(
								{
									mainCategoryId: mainCategoryId,
								},
								(success) => {
									setDataList(success);
								},
								() => {}
							);
						}
					},
					(fail) => {}
				);
				break;
			}
			case "SubSpeciality": {
				let body = {
					category: subCategoryValue,
					subCategoryName: name,
					id: id,
					edit: true,
					isActive: false,
				};
				addSubCategory(
					body,
					(success) => {
						if (success === "Added successfully") {
							getSubCategoryListByCategoryId(
								{ categoryId: subCategoryValue },
								(success) => {
									handleSubCategoryList(success);
								},
								() => {}
							);
						}
					},
					(fail) => {}
				);
				break;
			}
			default: {
				break;
			}
		}
	};

	useEffect(() => {
		record.name === "Languages" &&
			getLanguages(
				(success) => {
					setDataList(success.languages);
				},
				(fail) => {}
			);

		record.name === "Currency" &&
			getCurrency(
				(success) => {
					setDataList(success.units);
				},
				(fail) => {}
			);

		record.name === "Prefix" &&
			getPrefix(
				(success) => {
					setDataList(success.prefixes);
				},
				(fail) => {}
			);

		record.name === "Speciality" &&
			getMainCategoryList(
				(success) => setMainCategory(success.data),
				(fail) => {}
			);
		record.name === "Speciality" &&
			getCategoryListByMainCategoryId(
				{
					mainCategoryId,
				},
				(success) => {
					setDataList(success);
				},
				() => {}
			);
		record.name === "Speciality" &&
			getSubCategoryListByCategoryId(
				{ categoryId: subCategoryValue },
				(success) => {
					handleSubCategoryList(success);
				},
				() => {}
			);
	}, [record.name, refresh, mainCategoryId, subCategoryValue]);

	useEffect(() => {
		if (!isModalVisible) {
			setDataList([]);
			handleSubCategoryList([]);
		}
	}, [isModalVisible]);
	return (
		<Modal
			visible={isModalVisible}
			onCancel={toggleModal}
			footer={[]}
			width={"80%"}
			centered
			className="websiteWordsModal">
			{record.name === "Languages" ? (
				<div className="languagesModal">
					<h5>Languages</h5>
					<input
						type="text"
						value={input}
						onChange={(e) => setInput(e.target.value)}
					/>
					<Button className="addBtn" onClick={() => addNewLanguage()}>
						Add
					</Button>

					<div className="textEncapsulationBox">
						<Row>
							{dataList.map((data, dataIndex) => {
								return (
									<Col md={8} xs={24} sm={12} key={dataIndex}>
										<span className="textEncapsulation">
											{data.languageName}
											<img
												src={Pen}
												alt={data.languageName}
												id={data._id}
												onClick={(e) => {
													toggleEditModal(!isEditModalVisible);
													setRecordId(e.target.id);
													setRecordName(e.target.alt);
													setRecordType("Languages");
												}}
											/>
											<img
												src={Delete}
												alt="Delete"
												onClick={() =>
													handleDelete("Languages", data.languageName, data._id)
												}
											/>
										</span>
									</Col>
								);
							})}
						</Row>
					</div>
				</div>
			) : record.name === "Currency" ? (
				<div className="currencyModal">
					<h5>Currency</h5>
					<input
						type="text"
						value={input}
						onChange={(e) => setInput(e.target.value)}
					/>
					<Button className="addBtn" onClick={() => addNewUnit()}>
						Add
					</Button>

					<div className="textEncapsulationBox">
						<Row>
							{dataList.map((data) => {
								return (
									<Col md={8} xs={24} sm={12}>
										<span className="textEncapsulation">
											{data.unitName}
											<img
												src={Pen}
												alt={data.unitName}
												id={data._id}
												onClick={(e) => {
													toggleEditModal(!isEditModalVisible);
													setRecordId(e.target.id);
													setRecordName(e.target.alt);
													setRecordType("Currency");
												}}
											/>
											<img
												src={Delete}
												alt="Delete"
												onClick={() => {
													handleDelete("Currency", data.unitName, data._id);
												}}
											/>
										</span>
									</Col>
								);
							})}
						</Row>
					</div>
				</div>
			) : record.name === "Speciality" ? (
				<div className="speciality">
					<Row>
						<Col lg={12} xs={24}>
							<h5>Main Category</h5>
							<select
								name="speciality"
								id="speciality"
								style={{ width: "60%", marginBottom: "10px" }}
								onChange={(e) => {
									setMainCategoryId(e.target.value);
									getCategoryListByMainCategoryId(
										{
											mainCategoryId: e.target.value,
										},
										(success) => {
											setDataList(success);
										},
										() => {}
									);
								}}>
								<option value="" disabled selected>
									Select Main category
								</option>
								{mainCategory.map((mainCategory) => {
									return (
										<option value={mainCategory.mainCategoryId}>
											{mainCategory.mainCategoryName}
										</option>
									);
								})}
							</select>
							<h5>Speciality</h5>
							<input
								type="text"
								value={specialityInput}
								onChange={(e) => setSpecialityInput(e.target.value)}
							/>
							<Button className="addBtn" onClick={() => addNewSpeciality()}>
								Add
							</Button>

							<div className="textEncapsulationBox">
								<Row>
									{dataList.map((data) => {
										return (
											<Col md={8} xs={24} sm={12}>
												<span className="textEncapsulation">
													{data.categoryName}
													<img
														src={Pen}
														alt={data.categoryName}
														id={data._id}
														onClick={(e) => {
															toggleEditModal(!isEditModalVisible);
															setRecordId(e.target.id);
															setRecordName(e.target.alt);
															setRecordType("Speciality");
														}}
													/>
													<img
														src={Delete}
														alt="Delete"
														onClick={() => {
															handleDelete(
																"Speciality",
																data.categoryName,
																data._id
															);
														}}
													/>
												</span>
											</Col>
										);
									})}
								</Row>
							</div>
						</Col>
						<Col lg={12} xs={24}>
							<h5 className="subSpecialityTitle">Sub-Speciality</h5>
							<select
								name="subSpeciality"
								id="subSpeciality"
								style={{ width: "60%" }}
								onChange={(e) => {
									setSubCategoryValue(e.target.value);
									getSubCategoryListByCategoryId(
										{ categoryId: e.target.value },
										(success) => {
											handleSubCategoryList(success);
										},
										() => {}
									);
								}}
								value={subCategoryValue}>
								<option value="" disabled selected>
									Select SubCategory
								</option>
								{dataList.map((speciality) => {
									return (
										<option value={speciality._id}>
											{speciality.categoryName}
										</option>
									);
								})}
							</select>
							<input
								type="text"
								value={subSpecialityInput}
								onChange={(e) => setSubSpecialityInput(e.target.value)}
								style={{ marginTop: "10px" }}
							/>
							<Button className="addBtn" onClick={() => addNewSubCategory()}>
								Add
							</Button>
							<div className="textEncapsulationBox">
								<Row>
									{subCategoryList.map((data) => {
										return (
											<Col md={8} xs={24} sm={12}>
												<span className="textEncapsulation">
													{data.subCategoryName}
													<img
														src={Pen}
														alt={data.subCategoryName}
														id={data._id}
														onClick={(e) => {
															toggleEditModal(!isEditModalVisible);
															setRecordId(e.target.id);
															setRecordName(e.target.alt);
															setRecordType("SubSpeciality");
														}}
													/>
													<img
														src={Delete}
														alt="Delete"
														onClick={() => {
															handleDelete(
																"SubSpeciality",
																data.subCategoryName,
																data._id
															);
														}}
													/>
												</span>
											</Col>
										);
									})}
								</Row>
							</div>
						</Col>
					</Row>
				</div>
			) : (
				<div className="prefixModal">
					<h5>Prefix</h5>
					<input
						type="text"
						value={input}
						onChange={(e) => setInput(e.target.value)}
					/>
					<Button className="addBtn" onClick={() => addnewPrefix()}>
						Add
					</Button>

					<div className="textEncapsulationBox">
						<Row>
							{dataList.map((data) => {
								return (
									<Col md={8} xs={24} sm={12}>
										<span className="textEncapsulation">
											{data.name}
											<img
												src={Pen}
												alt={data.name}
												id={data._id}
												onClick={(e) => {
													toggleEditModal(!isEditModalVisible);
													setRecordId(e.target.id);
													setRecordName(e.target.alt);
													setRecordType("Prefix");
												}}
											/>
											<img
												src={Delete}
												alt="Delete"
												onClick={() => {
													handleDelete("Prefix", data.name, data._id);
												}}
											/>
										</span>
									</Col>
								);
							})}
						</Row>
					</div>
				</div>
			)}
			<div className="btnContainer">
				<Button className="secondaryBtn" onClick={() => handleSubmit()}>
					Done
				</Button>
			</div>
			<EditModal
				toggleEditModal={() => toggleEditModal(!isEditModalVisible)}
				isEditModalVisible={isEditModalVisible}
				recordId={recordId}
				recordName={recordName}
				recordType={recordType}
				refresh={(val) => setRefresh(val)}
				refreshState={refresh}
				mainCategoryId={mainCategoryId}
				subCategoryValue={subCategoryValue}
			/>
		</Modal>
	);
}

export default WebsiteWordsModal;
