import { getResource, postResource } from "../../services";

export const getAllSessions = (onSuccess, onFail) => {
  const path = "admin/GetAllSessions";
  getResource(path, onSuccess, onFail);
};

export const UpdateSessionStatus = (body, onSuccess, onFail) => {
  const path = "admin/UpdateSessionStatus";
  postResource(path, body, onSuccess, onFail);
};
