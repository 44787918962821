import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { GetAllReportedCoaches } from "../../../reportedCoaches/network";
import { GetAllPendingCoaches } from "../../../newAccountRequests/network";
import firebase from "firebase/app";

import "./sideMenu.css";
function SideMenu(props) {
  const [current, updateCurrent] = useState(props.current);
  const [reportedCoachesCount, setReportedCoachesCount] = useState(0);
  const [pendingCoachesCount, setPendingCoachesCount] = useState(0);
  const [dataSource, updateDataSource] = useState([]);
  const [newMsgNotification, updateNewMsgNotification] = useState(false);

  useEffect(() => {
    GetAllReportedCoaches(
      (success) => setReportedCoachesCount(success.length),
      (fail) => {}
    );
  }, []);

  const getFBUsers = () => {
    const db = firebase.database();
    var usersRef = db.ref("users");
    if (usersRef) {
      usersRef.on("value", (snapshot) => {
        if (snapshot.val()) {
          const values = Object.values(snapshot.val());
          updateDataSource(values);
        }
      });
    }
  };

  useEffect(() => {
    getFBUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isUnreadMsg = dataSource.every((user) => {
      return user.isAdminRead;
    });
    if (!isUnreadMsg) {
      updateNewMsgNotification(true);
    } else {
      updateNewMsgNotification(false);
    }

    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    GetAllPendingCoaches(
      (success) => {
        setPendingCoachesCount(success.coaches.length);
      },
      (fail) => {}
    );
  }, []);

  return (
    <aside className="sideMenu">
      <Menu
        selectedKeys={[current]}
        mode="inline"
        onClick={(e) => {
          updateCurrent(e.key);
          props.onClose();
        }}
      >
        {/* <Menu.Item key="Dashboard">
					<Link to="/dashboard">Dashboard</Link>
				</Menu.Item> */}
        <Menu.Item key="Sessions">
          <Link to="/sessions">Sessions</Link>
        </Menu.Item>
        <Menu.Item key="Gurus">
          <Link to="/gurus">Gurus</Link>
        </Menu.Item>
        <Menu.Item key="Client">
          <Link to="/clients">Clients</Link>
        </Menu.Item>
        <Menu.Item key="NewAccountRequests">
          <Link to="/newAccountRequests">New Account Requests</Link>
          <span className="notificationBox">
            <span className="notificationIcon">
              <span>{pendingCoachesCount}</span>
            </span>
          </span>
        </Menu.Item>
        <Menu.Item key="ReportedCoaches">
          <Link to="/reportedcoaches">Reported Coaches</Link>
          <span className="notificationBox">
            <span className="notificationIcon">
              <span>{reportedCoachesCount}</span>
            </span>
          </span>
        </Menu.Item>
        <Menu.Item key="ContactMessages">
          <Link to="contactmessages">Contact Messages</Link>
        </Menu.Item>
        <Menu.Item key="WebsiteWords">
          <Link to="websitewords">Website Words</Link>
        </Menu.Item>
        <Menu.Item key="LiveChat">
          <Link to="liveChat" className="d-flex align-items-center">
            <span>Live Chat </span>
            {newMsgNotification && <span className="isRepliedDot mx-2"></span>}
          </Link>
        </Menu.Item>
        <Menu.Item key="Admins">
          <Link to="admins">Admins</Link>
        </Menu.Item>
        <Menu.Item key="Discounts">
          <Link to="discounts">Discounts</Link>
        </Menu.Item>
        <Menu.Item key="Promocodes">
          <Link to="promocodes">Promocodes</Link>
        </Menu.Item>
        <Menu.Item key="sales">
          <Link to="sales">Sales</Link>
        </Menu.Item>
        <Menu.Item key="Payments">
          <Link to="payments">Payments</Link>
        </Menu.Item>
        <Menu.Item key="packages">
          <Link to="packages">Packages</Link>
        </Menu.Item>
        <Menu.Item key="events">
          <Link to="events">Events</Link>
        </Menu.Item>
        <Menu.Item key="showEvents">
          <Link to="showEvents">Events List</Link>
        </Menu.Item>
        <Menu.Item key="landingPage">
          <Link to="landingPage">Landing Page</Link>
        </Menu.Item>
      </Menu>
    </aside>
  );
}

export default SideMenu;
