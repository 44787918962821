import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import {
	AddLanguage,
	addUnit,
	AddPrefix,
	addCategory,
	addSubCategory,
} from "../../network";
import "./EditModal.css";
function EditModal({
	isEditModalVisible,
	toggleEditModal,
	recordName,
	recordId,
	refresh,
	refreshState,
	recordType,
	mainCategoryId,
	subCategoryValue,
}) {
	const [Namerecord, setNameRecord] = useState(recordName);

	useEffect(() => {
		setNameRecord(recordName);
	}, [recordName]);

	const handleEdit = () => {
		switch (recordType) {
			case "Languages": {
				let body = {
					isActive: true,
					languageName: Namerecord,
					edit: true,
					id: recordId,
				};
				AddLanguage(
					body,
					(success) => {
						if (success === "Added successfully") {
							toggleEditModal(!isEditModalVisible);
							refresh(!refreshState);
						}
					},
					(fail) => {}
				);
				break;
			}
			case "Currency": {
				let body = {
					unitName: Namerecord,
					isActive: true,
					id: recordId,
					edit: true,
				};
				addUnit(
					body,
					(success) => {
						if (success === "Added successfully") {
							toggleEditModal(!isEditModalVisible);
							refresh(!refreshState);
						}
					},
					(fail) => {}
				);
				break;
			}
			case "Prefix": {
				let body = {
					name: Namerecord,
					edit: true,
					id: recordId,
					isActive: true,
				};
				AddPrefix(
					body,
					(success) => {
						if (success === "Added successfully") {
							toggleEditModal(!isEditModalVisible);
							refresh(!refreshState);
						}
					},
					(fail) => {}
				);
				break;
			}
			case "Speciality": {
				let body = {
					isActive: true,
					categoryName: Namerecord,
					mainCategoryId: mainCategoryId,
					id: recordId,
					edit: true,
				};

				addCategory(
					body,
					(success) => {
						if (success === "Added successfully") {
							toggleEditModal(!isEditModalVisible);
							refresh(!refreshState);
						}
					},
					(fail) => {}
				);
				break;
			}
			case "SubSpeciality": {
				let body = {
					category: subCategoryValue,
					subCategoryName: Namerecord,
					id: recordId,
					edit: true,
					isActive: true,
				};

				addSubCategory(
					body,
					(success) => {
						if (success === "Added successfully") {
							toggleEditModal(!isEditModalVisible);
							refresh(!refreshState);
						}
					},
					(fail) => {}
				);

				break;
			}

			default: {
				break;
			}
		}
	};

	return (
		<Modal
			visible={isEditModalVisible}
			onCancel={toggleEditModal}
			footer={[]}
			width={"50%"}
			centered
			className="websiteWordsModal"
		>
			<h5>Edit</h5>
			<input
				type="text"
				value={Namerecord}
				onChange={(e) => setNameRecord(e.target.value)}
			/>
			<Button className="addBtn" onClick={() => handleEdit()}>
				Edit
			</Button>
		</Modal>
	);
}

export default EditModal;
