import React, { useState } from "react";
import { Modal, Col, Row, Button, Checkbox } from "antd";
import { Player, BigPlayButton, ControlBar } from "video-react";
import ConfirmationModal from "../../../common/components/confirmationModal/confirmationModal";
import { mediaBaseUrl } from "../../../../services";
import { AcceptRefusePendingCoaches } from "../../network";
import { toastError } from "../../../../helpers/toast";
import "./newAccountModal.css";

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function NewAccountModal({ isModalVisible, toggleModal, record, refresh }) {
  const [profComment, setProfComment] = useState("");
  const [checkedValuesArr, updateCheckedValuesArr] = useState([]);
  const [isConfModalVis, toggleConfModal] = useState(false);
  const [isCommentEmpty, updateIsCommentEmpty] = useState(false);
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  const [isDocModalVisible, toggleDocModal] = useState(false);
  const [itemClicked, setItemClicked] = useState(null);
  const onChange = (checkedValues) => {
    updateCheckedValuesArr(checkedValues);
  };
  const handleApprove = () => {
    let body = {
      id: record._id,
      isAccepted: true,
      sendEmail: false,
    };
    AcceptRefusePendingCoaches(
      body,
      (success) => {
        if (success.code === 1) {
          toggleModal();
          refresh(true);
        }
      },
      (fail) => {}
    );
  };
  const handleSubmitErr = () => {
    if (profComment) {
      let body = {
        id: record._id,
        isAccepted: true,
        sendEmail: true,
        responseMessage: profComment,
      };
      AcceptRefusePendingCoaches(
        body,
        (success) => {
          if (success.code === 1) {
            toggleModal();
            refresh(true);
          }
        },
        (fail) => {}
      );
    } else {
      updateIsCommentEmpty(true);
      toastError("Please Fill the profile comment");
    }
  };
  const handleBlock = () => {
    toggleConfModal(true);
  };

  // const onDocumentLoadSuccess = ({ numPages }) => {
  // 	setNumPages(numPages);
  // };
  // const next = () => {
  // 	if (pageNumber < numPages) {
  // 		setPageNumber(pageNumber + 1);
  // 	}
  // };

  // const back = () => {
  // 	if (pageNumber > 1) {
  // 		setPageNumber(pageNumber - 1);
  // 	}
  // };
  return (
    <Modal
      visible={isModalVisible}
      onCancel={toggleModal}
      footer={[]}
      width={"90%"}
      centered
      className="newAccountModal"
    >
      <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
        <Row>
          <Col xs={24} md={8}>
            <div className="checkBoxBlock">
              <Checkbox value="profilePicture">
                <label>Profile Picture</label>
                <div>
                  <img
                    src={mediaBaseUrl + record.profileImg}
                    alt="avatar"
                    className="avatarLg"
                  />
                </div>
              </Checkbox>
            </div>
            {record.bioVideo && record.bioVideo.length > 0 && (
              <div className="checkBoxBlock ">
                <Checkbox value="video">
                  <label>Bio Video</label>
                </Checkbox>
                <div className="videoContainer">
                  <Player
                    src={mediaBaseUrl + record.bioVideo}
                    width={120}
                    height={120}
                    fluid={false}
                    poster={mediaBaseUrl + record.profileImg}
                  >
                    <ControlBar autoHide={false} disableDefaultControls={true}>
                      <BigPlayButton position="center" />
                    </ControlBar>
                  </Player>
                </div>
              </div>
            )}
          </Col>

          <Col xs={24} md={16}>
            <Row>
              <Col xs={24} md={12}>
                <div className="checkBoxBlock">
                  <Checkbox value="name">
                    <label>Name</label>
                    <p>{record.guruName}</p>
                  </Checkbox>
                </div>
                <div className="checkBoxBlock">
                  <Checkbox value="bio" className="bio-container">
                    <label>Bio</label>
                    <p dangerouslySetInnerHTML={{ __html: record.bio }} />
                  </Checkbox>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="checkBoxBlock">
                  <Checkbox value="personalId">
                    <label>Personal ID</label>
                  </Checkbox>
                  <div className="docBox">
                    {record.personalId && (
                      <span
                        className="textEncapsulation d-flex cursorPointer"
                        onClick={(e) => {
                          setItemClicked(e.target.id);
                          toggleDocModal(!isDocModalVisible);
                        }}
                        id={mediaBaseUrl + record.personalId}
                      >
                        {/* {record.personalId.split("-")[1]} */}
                        Personal ID
                      </span>
                    )}
                  </div>
                </div>
                {record.documents.length > 0 && (
                  <div className="checkBoxBlock">
                    <Checkbox value="documents">
                      <label>Documents</label>
                    </Checkbox>

                    <div className="docBox">
                      <a
                        href={mediaBaseUrl + record.documents[0]}
                        className="textEncapsulation  d-flex cursorPointer"
                        target="_blank"
                        rel="noreferrer"
                        // onClick={(e) => {
                        // 	toggleDocModal(!isDocModalVisible);
                        // 	setItemClicked(e.target.id);
                        // }}
                        // id={mediaBaseUrl + record.documents}
                      >
                        {/* {record.documents[0].split("-")[1]} */}
                        Document{" "}
                      </a>
                    </div>
                  </div>
                )}
                <div className="checkBoxBlock">
                  <Checkbox value="pricePerHour">
                    <label>Price Per Hour</label>
                    <p style={{ color: "#CFA79D", fontSize: "16px" }}>
                      ${record.pricePerHpur}/hr
                    </p>
                  </Checkbox>
                </div>
              </Col>
            </Row>
            {checkedValuesArr.length > 0 && (
              <span>
                <label>Profile comments:</label>
                <textarea
                  value={profComment}
                  onChange={(e) => setProfComment(e.target.value)}
                  className={isCommentEmpty && "border-danger"}
                />
              </span>
            )}
          </Col>
        </Row>
      </Checkbox.Group>
      {checkedValuesArr.length > 0 ? (
        <div className="btnContainer">
          <Button className="secondaryBtn" onClick={() => handleSubmitErr()}>
            Confirm
          </Button>
        </div>
      ) : (
        <div className="btnContainer">
          <Button className="primaryBtn" onClick={() => handleBlock()}>
            Decline
          </Button>
          <Button className="secondaryBtn" onClick={() => handleApprove()}>
            Approve
          </Button>
        </div>
      )}
      <ConfirmationModal
        refresh={refresh}
        isModalVisible={isConfModalVis}
        toggleAllModal={() => {
          toggleConfModal(!isConfModalVis);
          setTimeout(() => toggleModal(!isModalVisible), 20);
        }}
        toggleModal={() => toggleConfModal(!isConfModalVis)}
        text={`Are You Sure Decline ${record.guruName} Profile?`}
        cancelText="Cancel"
        confirmText="Approve"
        record={record}
      />

      {itemClicked && (
        <Modal
          visible={isDocModalVisible}
          onCancel={() => toggleDocModal(!isDocModalVisible)}
          footer={[]}
          width={"90%"}
          centered
          className="newAccountModal"
        >
          {itemClicked.includes("pdf") ? (
            <div className="documentContainer">
              {/* <Document
								file={itemClicked}
								onLoadSuccess={onDocumentLoadSuccess}
							>
								<Page pageNumber={pageNumber} width={400} />
							</Document> */}
              <a href={itemClicked} target="_blank" rel="noreferrer">
                {itemClicked}
              </a>
            </div>
          ) : (
            <div className="imageContainer">
              <img src={itemClicked} alt="itemClicked" />
            </div>
          )}
        </Modal>
      )}
    </Modal>
  );
}

export default NewAccountModal;
