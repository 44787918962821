import React, { useState } from "react";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
import TableHeader from "../common/components/tableHeader/tableHeader";
import { Button, Form, Input, Spin, Upload } from "antd";
import imageIcon from "../../assets/icons/image-icon.svg";
import remove from "../../assets/icons/remove.svg";
import { useEffect } from "react";
import { addEditLandingPage, getLandingPage, imageUpload } from "./network";
import "./LandingPage.css";
import { toastSuccess } from "../../helpers/toast";

function LandingPage() {
  const [bannerImagePath, updateBannerImagePath] = useState("");
  const [logoPath, updateLogoPath] = useState("");
  const [loading, updateLoading] = useState(false);
  const [initialValues, updateInitialValues] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    // Get all the label elements on the page
    const labels = document.querySelectorAll("label");

    // Find the width of the largest label
    let maxWidth = 0;
    labels.forEach((label) => {
      const labelWidth = label.offsetWidth;
      if (labelWidth > maxWidth) {
        maxWidth = labelWidth;
      }
    });

    // Set the width of all labels to the width of the largest label
    labels.forEach((label) => {
      label.style.width = `${maxWidth}px`;
      label.style.textWrap = "nowrap";
    });
  }, [initialValues]);
  const onFinish = (values) => {
    if (!bannerImagePath || !logoPath) {
      if (!bannerImagePath) {
        form.setFields([
          {
            name: "bannerImage",
            errors: ["Please upload the banner image"],
          },
        ]);
      }
      if (!logoPath) {
        form.setFields([
          {
            name: "logo",
            errors: ["Please upload the logo"],
          },
        ]);
      }
    } else {
      const data = { ...values, bannerImage: bannerImagePath, logo: logoPath };
      if (initialValues) {
        data.id = initialValues?._id;
      }
      delete data.bannerPhoto;
      updateLoading(true);
      addEditLandingPage(
        data,
        (success) => {
          toastSuccess(success.message);
          updateLoading(false);
        },
        (fail) => {
          updateLoading(false);
        }
      );
    }
  };
  const handleCallGetData = () => {
    getLandingPage(
      (success) => {
        const data = success.data;
        updateInitialValues(data);
        updateBannerImagePath(data.bannerImage);
        updateLogoPath(data.logo);
      },
      (fail) => {
        // console.log(fail);
      }
    );
  };
  useEffect(() => {
    handleCallGetData();
  }, []);
  const handleUploadBanner = (file) => {
    const value = file.file;
    const isImage =
      value?.type?.toLowerCase().includes("png") ||
      value?.type.toLowerCase().includes("jpg") ||
      value?.type.toLowerCase().includes("jpeg") ||
      value?.type.toLowerCase().includes("svg");
    if (isImage) {
      updateLoading(true);
      const bannerFormData = new FormData();
      bannerFormData.append("image", value);
      imageUpload(
        bannerFormData,
        (success) => {
          updateBannerImagePath(success.path);
          updateLoading(false);
        },
        (fail) => {
          updateLoading(false);
        }
      );
    }
  };
  const handleUploadLogo = (file) => {
    const value = file.file;
    const isImage =
      value?.type?.toLowerCase().includes("png") ||
      value?.type?.toLowerCase().includes("jpg") ||
      value?.type?.toLowerCase().includes("jpeg") ||
      value?.type?.toLowerCase().includes("svg");
    if (isImage) {
      updateLoading(true);
      const logoFormData = new FormData();
      logoFormData.append("image", value);
      imageUpload(
        logoFormData,
        (success) => {
          updateLogoPath(success.path);
          updateLoading(false);
        },
        (fail) => {
          updateLoading(false);
        }
      );
    }
  };
  return (
    <div className="landing-page">
      <Navbar />
      <Header parent="Landing Page" />
      <TableHeader parent={"landingPage"} />
      <div className="my-3 content">
        <div className="f-30 fw-600 my-4">Banner Details</div>
        <Form
          key={initialValues}
          initialValues={initialValues}
          form={form}
          name="basic"
          onFinish={onFinish}
          requiredMark={false}
          colon={false}
          className="my-4"
        >
          <div className="d-flex my-4">
            <label>Banner Photo</label>
            <div>
              <Form.Item
                label=""
                name="bannerImage"
                rules={[
                  (a) => ({
                    validator: (rule, value) => {
                      const isImage =
                        value?.toLowerCase().includes("png") ||
                        value?.toLowerCase().includes("jpg") ||
                        value?.toLowerCase().includes("jpeg") ||
                        value?.type.toLowerCase().includes("svg");
                      if (!value) {
                        return Promise.reject("Please upload the banner image");
                      } else if (!isImage) {
                        return Promise.reject("Please upload images only");
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <div className="upload-area d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img src={imageIcon} alt="imageIcon" />

                    <Upload
                      name="bannerImage"
                      listType="picture"
                      customRequest={handleUploadBanner}
                    >
                      <div className="d-flex">
                        <div className="mx-2">
                          {loading ? (
                            <Spin />
                          ) : bannerImagePath ? (
                            bannerImagePath
                              .split("/")
                              [bannerImagePath.split("/").length - 1].slice(
                                0,
                                15
                              )
                          ) : (
                            "Upload Banner photo"
                          )}
                        </div>
                      </div>
                    </Upload>
                  </div>

                  <img
                    src={remove}
                    alt="remove"
                    className="cursorPointer"
                    onClick={() => {
                      form.setFields([{ bannerImage: null }]);
                      updateBannerImagePath(null);
                      updateInitialValues({
                        ...initialValues,
                        bannerImage: null,
                      });
                    }}
                  />
                </div>
              </Form.Item>

              <div className="text-red">
                Should not be more than 123*1215 and should be jpg, jpeg,svg and
                png
              </div>
            </div>
          </div>
          <div className="d-flex my-4">
            <label>Logo</label>
            <div>
              <Form.Item
                label=""
                name="logo"
                rules={[
                  (a) => ({
                    validator: (rule, value) => {
                      const isImage =
                        value?.toLowerCase().includes("png") ||
                        value?.toLowerCase().includes("jpg") ||
                        value?.toLowerCase().includes("jpeg") ||
                        value?.toLowerCase().includes("svg");
                      if (!value) {
                        return Promise.reject("Please upload the logo");
                      } else if (!isImage) {
                        return Promise.reject("Please upload images only");
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <div className="upload-area d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img src={imageIcon} alt="imageIcon" />
                    <Upload
                      name="logo"
                      listType="picture"
                      disabled={loading}
                      customRequest={handleUploadLogo}
                    >
                      <div className="d-flex">
                        <div className="mx-2">
                          {loading ? (
                            <Spin />
                          ) : logoPath ? (
                            logoPath
                              .split("/")
                              [logoPath.split("/").length - 1].slice(0, 25)
                          ) : (
                            "Upload logo photo"
                          )}
                        </div>
                      </div>
                    </Upload>
                  </div>

                  <img
                    src={remove}
                    alt="remove"
                    className="cursorPointer"
                    onClick={() => {
                      updateLogoPath(null);
                      form.setFieldsValue("logo", "");
                    }}
                  />
                </div>
              </Form.Item>
            </div>
          </div>

          <div className="d-flex my-4">
            <label>Upper Statement</label>
            <Form.Item
              label=""
              name="WordInTopOfBanner"
              rules={[
                {
                  required: "true",
                  message: "Please Enter the upper statement",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="d-flex my-4">
            <label>Lower Statement</label>
            <Form.Item
              label=""
              name="WordInBottomOfBanner"
              rules={[
                {
                  required: "true",
                  message: "Please Enter the lower statement",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="d-flex justify-content-center my-4">
            <Button
              htmlType="primary"
              type="primary"
              loading={loading}
              className="secondaryBtn mx-2"
            >
              Save
            </Button>
            <Button
              loading={loading}
              className="primaryBtn mx-2"
              onClick={() => {
                window.location.reload();
              }}
            >
              Discard
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default LandingPage;
