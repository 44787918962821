import { getResource, postResource } from "../../services";

export const getLandingPage = (onSuccess, onFail) => {
	const path = `landinPage/getLandingPage`;
	getResource(path, onSuccess, onFail);
};
export const addEditLandingPage = (data, onSuccess, onFail) => {
	const path = `landinPage/addOrEditLandingPage`;
	postResource(path, data, onSuccess, onFail);
};
export const imageUpload = (data, onSuccess, onFail) => {
	const path = `user/imageUpload`;
	postResource(path, data, onSuccess, onFail, true, true);
};
