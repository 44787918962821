import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AuthActions } from "../../../../Store/Actions/AuthActions";
import { LoginAdmin } from "../../network";
import "./loginForm.css";
function LoginForm(props) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errMsg, setErr] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		let body = {
			email: email.toLowerCase(),
			password,
		};
		LoginAdmin(
			body,
			(success) => {
				props.AuthActions(success.data[0]);
			},
			(fail) => {
				setErr(fail?.response?.data?.message);
			}
		);
	};

	return (
		<form className="loginForm" onSubmit={handleSubmit}>
			<div>
				<h2 className="mainHeading">Sign In</h2>
				<input
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder={"Email"}
				/>
				<div className="passwordBox">
					<input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						placeholder={"Password"}
					/>
					<div>
						<Link to="/forgetPassword">Forgot password?</Link>
					</div>
				</div>
				<div className="errMsg">{errMsg}</div>
				<button>Sign In</button>
			</div>
		</form>
	);
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		AuthActions: (auth) => dispatch(AuthActions(auth)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
