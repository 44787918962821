import React from "react";
import { Modal, Button } from "antd";
import { ActivatedAndDeactivateUsers } from "../../../gurus/network";
import "./confirmationModal.css";

function confirmationModal({
  isModalVisible,
  toggleModal,
  text,
  toggleAllModal,
  cancelText,
  confirmText,
  record,
  refresh,
}) {
  const handleDecline = () => {
    let body = {
      id: record._id,
      isActive: false,
      type: 2,
    };
    ActivatedAndDeactivateUsers(
      body,
      (success) => {
        if (success.code === 1) {
          toggleAllModal();
          refresh(true);
        }
      },
      (fail) => {}
    );
  };
  return (
    <Modal
      visible={isModalVisible}
      onCancel={toggleModal}
      footer={[]}
      width={"50%"}
      centered
      className="confirmationModal"
    >
      <div className="text">{text}</div>

      <div className="btnContainer">
        <Button className="primaryBtn" onClick={() => toggleModal()}>
          {cancelText}
        </Button>
        <Button className="secondaryBtn" onClick={() => handleDecline()}>
          {confirmText}
        </Button>
      </div>
    </Modal>
  );
}

export default confirmationModal;
