import React, { useEffect, useState } from "react";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
import TableHeader from "../common/components/tableHeader/tableHeader";
import Delete from "../../assets/icons/delete.svg";
import { DatePicker } from "antd";
import moment from "moment";
import { getCoachsDiscountPerDay, AddCoachesDisCountPerDay } from "./network";
import DiscountDetailsModal from "./Components/DiscountDetailsModal/DiscountDetailsModal";
import "./Discounts.css";

function Discounts() {
	const [datesDetails, updatedatesDetails] = useState([]);
	const [selectedDate, updateSelectedDate] = useState(null);
	const [modalState, updateModalState] = useState(false);
	useEffect(() => {
		getCoachsDiscountPerDay(
			(success) => {
				updatedatesDetails(success.data);
			},
			(fail) => {
				console.log(fail);
			}
		);
	}, []);

	function onChange(date, dateString) {
		var dates = [...datesDetails];
		dates.push({ date: dateString });
		var datesSet = new Set(dates);
		var datesArr = Array.from(datesSet);
		updatedatesDetails(datesArr);
	}
	const handleDeleteDay = (date, dateId, dateIndex) => {
		const allDatesDetails = [...datesDetails];
		allDatesDetails.splice(dateIndex, 1);
		let body = {
			date: moment(date).format("L"),
			actionStatus: 3,
			id: dateId,
		};
		AddCoachesDisCountPerDay(
			body,
			(success) => {
				if (success.code === 1) {
					updatedatesDetails(allDatesDetails);
				}
			},
			(fail) => {
				console.log(fail);
			}
		);
	};
	return (
		<div className="discounts">
			<Navbar />
			<Header parent="Discounts" />
			<TableHeader parent={"Discounts"} />
			<div className="m-4">
				<div className="d-flex align-items-center">
					<h4 className="mx-2">Available dates</h4>
					<DatePicker onChange={onChange} />
				</div>

				<div className="d-flex">
					{datesDetails.map((date, dateIndex) => {
						return (
							<div
								className="textEncapsulation m-2 cursorPointer"
								key={dateIndex}
							>
								<span
									onClick={() => {
										updateModalState(true);
										updateSelectedDate(date);
									}}
								>
									{date.date}
								</span>

								<img
									src={Delete}
									alt="Delete"
									className="mx-2"
									onClick={() => {
										handleDeleteDay(date.date, date.id, dateIndex);
									}}
								/>
							</div>
						);
					})}
				</div>
			</div>
			{selectedDate && (
				<DiscountDetailsModal
					toggleModal={() => {
						updateModalState(!modalState);
					}}
					isModalVisible={modalState}
					selectedDate={selectedDate}
				/>
			)}
		</div>
	);
}

export default Discounts;
