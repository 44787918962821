import axios from "axios";

//Testing
// const baseUrl = "https://weloapi.kadabraservices.com/";
// export const mediaBaseUrl = "https://weloapi.kadabraservices.com/";

//Live
// const baseUrl = "https://weloapi.welo.app/";
// export const mediaBaseUrl = "https://weloapi.welo.app/";

//Local
// const baseUrl = "http://localhost:6001/";
// export const mediaBaseUrl = "https://weloapi.kadabraservices.com/";

const baseUrl = process.env.REACT_APP_BASE_URL;
export const mediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL;

export const postResource = (path, body, onSuccess, onFail) => {
  axios({
    method: "post",
    url: baseUrl + path,
    data: body,
  })
    .then((success) => onSuccess(success.data))
    .catch((err) => onFail(err));
};

export const getResource = (path, onSuccess, onFail) => {
  axios({
    method: "get",
    url: baseUrl + path,
  })
    .then((success) => onSuccess(success.data))
    .catch((err) => onFail(err));
};

export const deleteResource = (body, path, onSuccess, onFail) => {
  axios({
    method: "delete",
    url: baseUrl + path,
    data: body,
  })
    .then((success) => onSuccess(success.data))
    .catch((err) => onFail(err));
};
