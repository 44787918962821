import React, { useEffect } from "react";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
import TableHeader from "../common/components/tableHeader/tableHeader";
import { useState } from "react";
import { Button, Table } from "antd";
import { deleteEventById, getAllEvents } from "./network";
import EventDetailsModal from "./EventDetailsModal";
import { toastError, toastSuccess } from "../../helpers/toast";

function ShowEvents() {
  const [dataSource, updateDataSource] = useState([]);
  const [isModalVisible, updateIsModalVisible] = useState(false);

  useEffect(() => {
    const data = {
      timeZone: "Africa/Addis_Ababa",
    };
    getAllEvents(
      data,
      (success) => {
        updateDataSource(success.data);
      },
      (fail) => {}
    );
  }, []);

  const handleDelete = (eventId) => {
    console.log(eventId);
    const newData = dataSource.filter((item) => {
      return item.eventId !== eventId;
    });

    const data = {
      id: eventId,
    };

    deleteEventById(
      data,
      (success) => {
        console.log(success.data);
        updateDataSource(newData);
        toastSuccess("Event deleted successfully!");
      },
      (fail) => {
        console.log(fail.data);
        toastError("Oops some one booked this event!");
      }
    );
  };

  const columns = [
    {
      title: "Guru Name",
      dataIndex: "guruName",
      key: "guruName",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          // toggleModal(!isModalVisible);
          updateIsModalVisible({ record });
        },
      }),
    },
    {
      title: "Event Name",
      dataIndex: "name",
      key: "name",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          // toggleModal(!isModalVisible);
          updateIsModalVisible({ record });
        },
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 300,
      onCell: (record, rowIndex) => ({
        onClick: () => {
          // toggleModal(!isModalVisible);
          updateIsModalVisible({ record });
        },
      }),
    },
    {
      title: "Dates",
      dataIndex: "eventDates",
      key: "eventDates",
      render: (eventDates) => {
        return (
          <div>
            {eventDates?.map((date) => (
              <div>{date?.fullDate}</div>
            ))}
          </div>
        );
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          // toggleModal(!isModalVisible);
          updateIsModalVisible({ record });
        },
      }),
    },
    {
      title: "All event seats",
      dataIndex: "noOfSeats",
      key: "noOfSeats",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          // toggleModal(!isModalVisible);
          updateIsModalVisible({ record });
        },
      }),
    },
    {
      title: "Remaining seats",
      dataIndex: "noOfRemainingSeats",
      key: "noOfRemainingSeats",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          // toggleModal(!isModalVisible);
          updateIsModalVisible({ record });
        },
      }),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          updateIsModalVisible({ record });
        },
      }),
    },
    {
      title: "Event Price",
      dataIndex: "eventCost",
      key: "eventCost",
      render: (eventCost) => {
        return <div>{eventCost} USD</div>;
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          updateIsModalVisible({ record });
        },
      }),
    },
    {
      title: "Action",
      key: "eventId",
      dataIndex: "eventId",
      render: (eventId, record) => (
        <Button onClick={() => handleDelete(eventId)}>Delete</Button>
      ),
    },
  ];

  return (
    <div>
      <Navbar />
      <Header parent="All Events" />
      <TableHeader parent={"allEvents"} />
      <div className="my-3">
        <Table
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: false, y: "500" }}
          pagination={{ position: ["bottomCenter"] }}
        />
      </div>
      <EventDetailsModal
        onCancel={() => {
          updateIsModalVisible(false);
        }}
        isModalVisible={isModalVisible}
      />
    </div>
  );
}

export default ShowEvents;
