import React, { useEffect, useState } from "react";
import { Button, Input, Select } from "antd";
import { getAllUsers } from "../../../gurus/network";
import { createPromocode } from "../../network";
import { Checkbox } from "antd";
import { toastError } from "../../../../helpers/toast";
import "./PromocodeForGuru.css";
function PromocodeForGuru({ callGetPromocodes, dataSource, salesPersonsData }) {
  const [percent, updatePercent] = useState(null);
  const [selectedGurus, updateSelectedGurus] = useState([]);
  const [options, updateOptions] = useState([]);
  const [promocode, updatePromocode] = useState(null);
  const [isUsedMultipleTimes, updateIsUsedMultipleTimes] = useState(false);
  const [selectedSalesPerson, updateSelectedSalesPerson] = useState(null);
  const [matchingText, updateMatchingText] = useState();
  const { Option } = Select;

  const onIsUsedMultipleTimesChange = (e) => {
    updateIsUsedMultipleTimes(e.target.checked);
  };
  useEffect(() => {
    let data = [];
    getAllUsers(
      (success) => {
        success.users.forEach((user) => {
          data.push({ name: user.fullName, value: user._id });
        });
        updateOptions(data);
      },
      (fail) => {
        console.log(fail);
      }
    );
  }, []);
  const handleGeneratePromocode = () => {
    const isNotUniquePromocode = dataSource.some(
      (promoCode) => promoCode.coupon === promocode
    );
    if (isNotUniquePromocode) {
      toastError("Promocode is used before!");
    } else {
      if (percent && selectedGurus) {
        if (percent) {
          if (percent >= 90 && percent <= 99) {
            toastError("Percentage between 90 and 99 are not allowed!");
          } else {
            let data = {
              discountPercentage: percent > 100 ? 100 : percent,
              couponType: 2,
              coachesIds: selectedGurus,
              promocode: promocode ? promocode : null,
              isSingleUsage: !isUsedMultipleTimes,
              salesPersonId: selectedSalesPerson,
              matchingText: matchingText ? matchingText.trim() : null,
            };

            createPromocode(
              data,
              (success) => {
                if (success.code === 1) callGetPromocodes();
              },
              (fail) => {
                console.log(fail);
              }
            );
          }
        }
      } else {
        toastError("Please provide a valid Percent or select a guru!");
      }
    }
  };
  const handleChangeSalesPerson = (salesPersonId) => {
    updateSelectedSalesPerson(salesPersonId);
  };

  return (
    <div className="promocodeForGuru m-4 d-flex justify-content-center flex-wrap align-items-center">
      <Input
        type={"number"}
        max={100}
        min={1}
        placeholder="Percent %"
        className="percentBox text-center m-2"
        value={percent}
        onChange={(e) => {
          updatePercent(e.target.value);
        }}
      />

      <Select
        mode="multiple"
        style={{ width: 300 }}
        onChange={(selectedGurusNames, selectedGurus) => {
          const selectedGurusId = selectedGurus.map((guru) => guru.key);
          updateSelectedGurus(selectedGurusId);
        }}
        className="m-2"
        placeholder="Select a guru"
      >
        {options.map((guru) => {
          return (
            <Option value={guru.name} key={guru.value}>
              {guru.name}
            </Option>
          );
        })}
      </Select>
      <Select
        style={{ width: 300 }}
        onChange={handleChangeSalesPerson}
        className="m-2"
        placeholder={"Select a sales person (Optional)"}
      >
        {salesPersonsData.map((person) => {
          return (
            <Option value={person.salesPersonId} key={person.salesPersonId}>
              {person.name}
            </Option>
          );
        })}
      </Select>
      <Input
        type={"text"}
        placeholder="Promocode (optional)"
        className="text-center m-2"
        value={promocode}
        style={{ width: "200px" }}
        onChange={(e) => {
          updatePromocode(e.target.value);
        }}
      />
      <Input
        type={"text"}
        placeholder="Client's Matching domains"
        className="text-center m-2"
        value={matchingText}
        style={{ width: "200px" }}
        onChange={(e) => {
          updateMatchingText(e.target.value);
        }}
      />

      <Checkbox onChange={onIsUsedMultipleTimesChange} className="my-2">
        Used Multiple Times
      </Checkbox>
      <Button className="btn m-2" onClick={handleGeneratePromocode}>
        Generate
      </Button>
    </div>
  );
}

export default PromocodeForGuru;
