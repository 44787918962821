import React, { useState } from "react";
import { Modal, Row, Col, Button } from "antd";
import { AddAdmin } from "../../network";
import "./AddAdminsModal.css";
function AddAdminsModal({ isModalVisible, toggleModal, refresh }) {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [errFlag, setErrFlag] = useState(false);

	const handelConfirm = () => {
		if (
			firstName.length > 0 &&
			lastName.length > 0 &&
			email.length > 0 &&
			password.length > 0 &&
			phone.length > 0
		) {
			let body = {
				firstName,
				lastName,
				email: email.toLowerCase(),
				password,
				phone,
			};
			AddAdmin(
				body,
				(success) => {
					setFirstName("");
					setLastName("");
					setEmail("");
					setPhone("");
					setPassword("");
					setErrFlag("");
					toggleModal();
					refresh(true);
				},
				(fail) => {}
			);
		} else {
			setErrFlag(true);
		}
	};
	return (
		<Modal
			visible={isModalVisible}
			onCancel={toggleModal}
			footer={[]}
			width={"90%"}
			centered
			className="addAdminsModal"
		>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handelConfirm();
				}}
			>
				<h5>Create New Admin</h5>
				<Row>
					<Col xs={24} md={12}>
						<input
							type="text"
							placeholder="First Name"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							className={
								errFlag && firstName.length === 0 ? "inputErr input" : "input"
							}
						/>
						<input
							type="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className={
								errFlag && email.length === 0 ? "inputErr input" : "input"
							}
						/>
						<input
							type="password"
							placeholder="Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className={
								errFlag && password.length === 0 ? "inputErr input" : "input"
							}
						/>
					</Col>

					<Col xs={24} md={12}>
						<input
							type="text"
							placeholder="Last Name"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							className={
								errFlag && lastName.length === 0 ? "inputErr input" : "input"
							}
						/>
						<input
							type="number"
							placeholder="Phone Number"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							className={
								errFlag && phone.length === 0 ? "inputErr input" : "input"
							}
						/>
					</Col>
				</Row>
				<div className="btnContainer" style={{ marginTop: "30px" }}>
					<Button
						className="primaryBtn"
						onClick={() => {
							toggleModal();
						}}
					>
						Cancel
					</Button>
					<input type="submit" value="Confirm" className="secondaryBtn" />
				</div>
			</form>
		</Modal>
	);
}

export default AddAdminsModal;
