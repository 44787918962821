import React, { useEffect, useState } from "react";
//import SideMenu from "../common/components/sideMenu/sideMenu";
import Grid from "../common/components/grid/grid";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
import ContactMessagesModal from "./components/contactMessagesModal/contactMessagesModal";
import moment from "moment";
import { Row, Col, Input, Button, Space } from "antd";
import { getAllContactMessages } from "./network";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import "./styles/contactMessages.css";
function ContactMessages() {
  const [contactMsgs, setContactMsgs] = useState([]);
  const [isModalVisible, toggleModal] = useState(false);
  const [record, setRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // eslint-disable-next-line
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            //			searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          //value={searchText[0]}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //	setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        if (a.name < b.name) return -1;
        else return 1;
      },
      render: (name, _) => {
        return (
          <div>
            {!_.isReplied && <span className="isRepliedDot"></span>}
            {name}
          </div>
        );
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          setRecord(record);
          toggleModal(!isModalVisible);
        },
      }),
      // ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => {
        if (a.email < b.email) return -1;
        else return 1;
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          setRecord(record);
          toggleModal(!isModalVisible);
        },
      }),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => {
        if (a.mobile < b.mobile) return -1;
        else return 1;
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          setRecord(record);
          toggleModal(!isModalVisible);
        },
      }),
    },
    {
      title: "Date Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        if (a.createdAt < b.createdAt) return -1;
        else return 1;
      },

      render: (createdAt) => {
        return <div>{moment(createdAt).format("DD/MM/YYYY , h:mm:ss a")}</div>;
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          setRecord(record);
          toggleModal(!isModalVisible);
        },
      }),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      sorter: (a, b) => {
        if (a.message < b.message) return -1;
        else return 1;
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          setRecord(record);
          toggleModal(!isModalVisible);
        },
      }),
      render: (message) => {
        return <div className="message-box">{message}</div>;
      },
    },
  ];
  useEffect(() => {
    getAllContactMessages(
      (success) => {
        setContactMsgs(success.data);
      },
      (fail) => {}
    );
  }, []);
  return (
    <div className="contactMessages">
      <Navbar />
      <Header parent="Contact Messages" />
      <Row>
        {/* <Col xs={4}>
					<SideMenu current="Contact Messages" />
				</Col> */}
        <Col xs={24}>
          <Grid
            dataSource={contactMsgs}
            columns={columns}
            parent="ContactMessages"
          />
        </Col>
      </Row>
      {record && (
        <ContactMessagesModal
          isModalVisible={isModalVisible}
          toggleModal={() => toggleModal(!isModalVisible)}
          record={record}
          refresh={(val) => {
            if (val)
              getAllContactMessages(
                (success) => {
                  setContactMsgs(success.data);
                },
                (fail) => {}
              );
          }}
        />
      )}
    </div>
  );
}

export default ContactMessages;
