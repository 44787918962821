import React, { Component } from "react";
import { Confirmemail } from "../../network";
import { Redirect } from "react-router-dom";
import { Alert } from "react-bootstrap";
import "./ForgetPasswordForm.css";

export default class ForgetPasswordForm extends Component {
	state = {
		email: "",
		msg: "",
		closeWindow: false,
	};
	onChange = (e) => {
		this.setState({ email: e.target.value });
	};
	handleSubmit = (e) => {
		e.preventDefault();
		let body = {
			email: this.state.email,
		};
		Confirmemail(
			body,
			(success) => {
				this.setState({ msg: success.message });
			},
			(fail) => {}
		);
		// Confirmemail(
		//     body,
		//     (success) => {
		//         setFirstName("");
		//         setLastName("");
		//         setEmail("");
		//         setPhone("");
		//         setPassword("");
		//         setErrFlag("");
		//         toggleModal();
		//         refresh(true);
		//     },
		//     (fail) => {}
		// );		// axios({
		// 	method: "post",
		// 	url: baseURL + "/user/forgotPassword",
		// 	data: {
		// 		email: this.state.email,
		// 	},
		// }).then((res) => this.setState({ msg: res.data.message }));
	};

	render() {
		if (this.state.closeWindow) return <Redirect to="/" />;
		return (
			<div className="emailConfirmation">
				<form onSubmit={this.handleSubmit}>
					<div className="inputBox">
						<label>Email</label>
						<input
							type="Email"
							value={this.state.email}
							onChange={this.onChange}
							className="form-control"
						/>
						{this.state.msg.length > 0 && (
							<Alert
								className="mt-2 show msg"
								variant={
									this.state.msg === "Mail Sent Succeessfully"
										? "success"
										: "danger"
								}
							>
								{this.state.msg}
							</Alert>
						)}
					</div>
					<div className="btnContainer">
						<span
							onClick={() => this.setState({ closeWindow: true })}
							className="btn"
						>
							Close
						</span>
						<input type="submit" value="Done" className="btn" />
					</div>
				</form>{" "}
			</div>
		);
	}
}
