import React, { useState, useEffect } from "react";
import { Button, Input, Select } from "antd";
import SelectSearch, { fuzzySearch } from "react-select-search";
import moment from "moment";
import { getAllUsers } from "../../../gurus/network";
import { createPromocode } from "../../network";
import { toastError } from "../../../../helpers/toast";
import "./PromocodeForSession.css";
function PromocodeForSession({
	callGetPromocodes,
	dataSource,
	salesPersonsData,
}) {
	const [percent, updatePercent] = useState(null);
	const [guruId, updateGuruId] = useState(null);
	const [options, updateOptions] = useState([]);
	const [gurusList, updateGurusList] = useState([]);
	const [sessionsList, updateSessionsList] = useState([]);
	const [sessionId, updateSessionId] = useState(null);
	const [promocode, updatePromocode] = useState(null);
	const [selectedSalesPerson, updateSelectedSalesPerson] = useState(null);
	const { Option } = Select;

	function handleDateChange(value) {
		updateSessionId(value);
	}
	useEffect(() => {
		let data = [];
		getAllUsers(
			(success) => {
				updateGurusList(success.users);
				success.users.forEach((user) => {
					data.push({
						name: user.fullName,
						value: user._id,
					});
				});
				updateOptions(data);
			},
			(fail) => {
				console.log(fail);
			}
		);
	}, []);
	const handleGetSessions = (guruId) => {
		let sessions = [];
		gurusList.forEach((guru) => {
			if (guru._id === guruId) {
				guru.schedulesNew.forEach((date) => {
					if (date.times.length > 0) {
						date.times.forEach((time) => {
							if (
								new Date(time.startDateTime).getTime() > new Date().getTime()
							) {
								sessions.push({ date: time.startDateTime, id: time._id });
							}
						});
					}
				});
			}
		});
		updateSessionsList(sessions);
	};
	const handleGeneratePromocode = () => {
		const isNotUniquePromocode = dataSource.some(
			(promoCode) => promoCode.coupon === promocode
		);
		if (isNotUniquePromocode) {
			toastError("Promocode is used before!");
		} else {
			if (percent && sessionId) {
				if (percent) {
					if (percent >= 90 && percent <= 99) {
						toastError("Percentage between 90 and 99 are not allowed!");
					} else {
						let data = {
							discountPercentage: percent > 100 ? 100 : percent,
							couponType: 3,
							coachId: guruId,
							timeSlotId: sessionId,
							promocode,
							isSingleUsage: false,
							salesPersonId: selectedSalesPerson,
						};
						createPromocode(
							data,
							(success) => {
								if (success.code === 1) callGetPromocodes();
							},
							(fail) => {
								console.log(fail);
							}
						);
					}
				}
			} else {
				toastError("Please provide a valid Percent or select a Session!");
			}
		}
	};

	const handleChangeSalesPerson = (salesPersonId) => {
		updateSelectedSalesPerson(salesPersonId);
	};
	return (
		<div>
			<div className="promocodeForSession m-4 d-flex justify-content-center">
				<Input
					type={"number"}
					max={100}
					min={1}
					placeholder="Percent %"
					className="percentBox text-center mx-2"
					value={percent}
					onChange={(e) => {
						updatePercent(e.target.value);
					}}
				/>
				<SelectSearch
					options={options}
					search
					value={guruId}
					placeholder="Select a guru"
					filterOptions={fuzzySearch}
					onChange={(guruId) => {
						updateGuruId(guruId);
						handleGetSessions(guruId);
						updateSessionId(null);
					}}
				/>
				<Select
					style={{ width: 300 }}
					onChange={handleDateChange}
					className="mx-2"
					disabled={!sessionsList.length}
					placeholder={"Select a session"}>
					{sessionsList.map((session) => {
						return (
							<Option value={session.id} key={session.id}>
								{moment(session.date).format("LLL Z")}
							</Option>
						);
					})}
				</Select>
				<Select
					style={{ width: 300 }}
					onChange={handleChangeSalesPerson}
					className="mx-2"
					disabled={!sessionsList.length}
					placeholder={"Select a sales person (Optional)"}>
					{salesPersonsData.map((person) => {
						return (
							<Option value={person.salesPersonId} key={person.salesPersonId}>
								{person.name}
							</Option>
						);
					})}
				</Select>
				<Input
					type={"text"}
					placeholder="Promocode (optional)"
					className="text-center mx-2"
					value={promocode}
					style={{ width: "200px" }}
					onChange={(e) => {
						updatePromocode(e.target.value);
					}}
				/>

				<Button className="btn mx-2" onClick={handleGeneratePromocode}>
					Generate
				</Button>
			</div>
		</div>
	);
}

export default PromocodeForSession;
