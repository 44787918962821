import React, { useState, useEffect } from "react";
import { Modal, Table, Button } from "antd";
import Delete from "../../../../assets/icons/delete.svg";
import SelectSearch from "react-select-search";
import { getAllUsers } from "../../../gurus/network";
import { AddCoachesDisCountPerDay } from "../../network";
import moment from "moment";
import Fuse from "fuse.js";
import "./selectSearchStyle.css";
import "./DiscountDetailsModal.css";
function DiscountDetailsModal({ isModalVisible, toggleModal, selectedDate }) {
	const [gurusSuggestions, updateGurusSuggestions] = useState([]);
	const [coaches, updateCoaches] = useState([]);
	useEffect(() => {
		getAllUsers(
			(success) => {
				let allGurus = [];
				success.users.forEach((guru) => {
					allGurus.push({ name: guru.fullName, value: guru._id });
				});
				updateGurusSuggestions(allGurus);
			},
			(fail) => {
				console.log(fail);
			}
		);

		updateCoaches(selectedDate.coaches);
	}, [selectedDate.coaches]);
	const handleChangeDiscount = (e, guruIndex) => {
		let coachesList = [...coaches];
		coachesList[guruIndex].discountPerDay =
			e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value;

		updateCoaches(coachesList);
	};

	const removeGuru = (guruIndex) => {
		let coachesList = [...coaches];
		coachesList.splice(guruIndex, 1);
		updateCoaches(coachesList);
	};

	const handleConfirm = () => {
		let body = {
			date: moment(selectedDate.date).format("l"),
			coaches,
			sessionLimitPerDay: 50,
			clientLimitPerDay: 1,
			actionStatus: selectedDate.id ? 2 : 1,
			id: selectedDate.id ? selectedDate.id : null,
		};
		AddCoachesDisCountPerDay(
			body,
			(success) => {
				if (success.code === 1) {
					toggleModal();
				}
			},
			(fail) => {
				console.log(fail);
			}
		);
	};

	function fuzzySearch(options) {
		const fuse = new Fuse(options, {
			keys: ["name", "groupName", "items.name"],
			threshold: 0.3,
		});

		return (value) => {
			if (!value.length) {
				return options;
			}

			return fuse.search(value);
		};
	}
	const columns = [
		{
			title: "",
			dataIndex: "remove",
			key: "remove",
			render: (remove, _, guruIndex) => {
				return (
					<img
						src={Delete}
						alt="Delete"
						className="cursorPointer deleteIcon"
						onClick={() => removeGuru(guruIndex)}
					/>
				);
			},
			width: 50,
		},
		{
			title: "Guru Name",
			dataIndex: "coachName",
			key: "coachName",
		},
		{
			title: "Discount",
			dataIndex: "discountPerDay",
			key: "discountPerDay",
			render: (discountPerDay, _, guruIndex) => {
				return (
					<input
						type="number"
						min="0"
						max="100"
						value={discountPerDay}
						className="form-control text-center"
						style={{ width: "50%", margin: "auto" }}
						onChange={(e) => {
							handleChangeDiscount(e, guruIndex);
						}}
					/>
				);
			},
		},
	];
	const handleSelectGuru = (guruId, record) => {
		let selectedCoaches = coaches ? [...coaches] : [];
		selectedCoaches.push({
			coachId: record.value,
			coachName: record.name,
			discountPerDay: "0",
		});
		var selectedCoachesSet = new Set(selectedCoaches);
		var selectedCoachesArr = Array.from(selectedCoachesSet);

		updateCoaches(selectedCoachesArr);
	};
	return (
		<Modal
			visible={isModalVisible}
			onCancel={toggleModal}
			footer={[]}
			width={"90%"}
			centered
			className="discountDetailsModal"
		>
			<div className="d-flex my-2">
				<Button className="secondaryBtn mx-2">Add Guru</Button>
				<SelectSearch
					options={gurusSuggestions}
					value="sv"
					name="Gurus"
					search={true}
					placeholder="Pick a Guru"
					onChange={handleSelectGuru}
					filterOptions={fuzzySearch}
				/>
			</div>
			<div className="my-4">
				<Table
					dataSource={coaches}
					columns={columns}
					pagination={{ position: ["bottomCenter"] }}
					scroll={{ y: 200 }}
				/>
			</div>
			<div className="btnContainer">
				<Button className="primaryBtn" onClick={toggleModal}>
					Cancel
				</Button>
				<Button className="secondaryBtn" onClick={handleConfirm}>
					Confirm
				</Button>
			</div>
		</Modal>
	);
}

export default DiscountDetailsModal;
