import { getResource, postResource } from "../../services";

export const getAllUsers = (onSuccess, onFail) => {
  const path = "admin/GetAllUsers?type=2";
  getResource(path, onSuccess, onFail);
};
export const ActivatedAndDeactivateUsers = (body, onSuccess, onFail) => {
  const path = "admin/ActivatedAndDeactivateUsers";
  postResource(path, body, onSuccess, onFail);
};
export const AddSessionDiscount = (body, onSuccess, onFail) => {
  const path = "coache/AddSessionDiscount";
  postResource(path, body, onSuccess, onFail);
};
export const bookSession = (body, onSuccess, onFail) => {
  const path = "user/bookSessionNew";
  postResource(path, body, onSuccess, onFail);
};
export const EditAvailability = (body, onSuccess, onFail) => {
  const path = "coache/EditAvailabilityNew";
  postResource(path, body, onSuccess, onFail);
};

export const AddCoachZoomEmail = (body, onSuccess, onFail) => {
  const path = "admin/AddCoachZoomEmail";
  postResource(path, body, onSuccess, onFail);
};

export const EditGuruDetails = (body, onSuccess, onFail) => {
  const path = "coache/EditBioAndName";
  postResource(path, body, onSuccess, onFail);
};
export const NotifyChangeSchedule = (body, onSuccess, onFail) => {
  const path = "account/NotifyChangeSchedule";
  postResource(path, body, onSuccess, onFail);
};
export const checkValidBook = (body, onSuccess, onFail) => {
  const path = "user/CheckValidBooking";
  postResource(path, body, onSuccess, onFail);
};
