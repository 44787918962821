import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Spin,
  Upload,
  message,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { mediaBaseUrl } from "../../../../services";
import uploadImg from "../../../../assets/icons/uploadPhoto.svg";
import { getAllUsers } from "../../../gurus/network";
import { postEvent, uploadEventCover } from "../../network";
import { toastSuccess } from "../../../../helpers/toast";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
// import ImgCrop from "antd-img-crop";
import "./AddEvent.css";

function AddEvent() {
  const [imageURL, updateImageURL] = useState(null);
  const [gurusList, updateGurusList] = useState([]);
  const [loading, updateLoading] = useState(false);
  const [guruId, updateGuruId] = useState(null);
  const [locationDisabled, setLocationDisabled] = useState(true);
  const [dates, updateDates] = useState([
    {
      fullDate: "",
      times: [],
      id: uuidv4(),
    },
  ]);

  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  const [form] = Form.useForm();

  // const range = (start, end) => {
  //   const result = [];
  //   for (let i = start; i < end; i++) {
  //     result.push(i);
  //   }
  //   return result;
  // };

  useEffect(() => {
    getAllUsers(
      (success) => {
        let allGurus = [];
        allGurus = success.users.map((user) => {
          return { value: user._id, label: user.fullName };
        });
        updateGurusList(allGurus);
      },
      (fail) => {}
    );
  }, []);

  const handleChooseGuru = (guruId) => {
    updateGuruId(guruId);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleReset = () => {
    form.resetFields();
    updateImageURL(null);
  };

  const onFinish = (values) => {
    if (values.status === "online") {
      delete values.location;
    }
    const data = {
      ...values,
      guruId,
      image: imageURL,
      status: values.status ? values.status : "online",
    };

    const dataKeys = Object.keys(data);
    const datesKeys = dataKeys
      .map((key) => {
        var returnedKey = null;
        if (key.includes("date")) {
          returnedKey = key;
        }
        return returnedKey;
      })
      .filter((date) => date);

    const isDateAddedBefore = (addedDate) =>
      eventDates.findIndex((date) => {
        return date.fullDate === moment(addedDate).format("DD/MM/YYYY");
      });

    const eventDates = [];

    datesKeys.forEach((key) => {
      if (isDateAddedBefore(data[key][0]) !== -1) {
        eventDates[isDateAddedBefore(data[key][0])].times.push({
          startDate: new Date(data[key][0]).toISOString(),
          endDate: new Date(data[key][1]).toISOString(),
        });
      } else {
        eventDates.push({
          fullDate: moment(data[key][0]).format("YYYY-MM-DD"),
          times: [
            {
              startDate: new Date(data[key][0]).toISOString(),
              endDate: new Date(data[key][1]).toISOString(),
            },
          ],
        });
      }
    });

    data.eventDates = eventDates;

    updateLoading(true);

    postEvent(
      data,
      (success) => {
        updateLoading(false);
        toastSuccess(success.message);
        handleReset();
      },
      (fail) => {
        updateLoading(false);
      }
    );
  };

  // const handleUploadCover = (e) => {
  //   const file = e.file;
  //   if (file.type.includes("image")) {
  //     let imageFormData = new FormData();
  //     imageFormData.append("imageEvent", file);
  //     updateLoading(true);
  //     uploadEventCover(
  //       imageFormData,
  //       (success) => {
  //         updateLoading(false);
  //         updateImageURL(success.path);
  //       },
  //       (fail) => {
  //         updateLoading(false);
  //       }
  //     );
  //   }
  // };

  // const handleUploadCover = (e) => {
  //   const file = e.file;
  //   const fileSizeLimit = 1024; // In bytes, example: 1MB
  //   // Check if the file size exceeds the limit
  //   if (file.size / 1024 > fileSizeLimit) {
  //     message.error("Size exceeds the limit, The size must be Less than 1MB");
  //     return;
  //   }

  //   if (file.type.includes("image")) {
  //     // Wait for the image to load
  //     let imageFormData = new FormData();
  //     imageFormData.append("imageEvent", file);
  //     updateLoading(true);
  //     uploadEventCover(
  //       imageFormData,
  //       (success) => {
  //         console.log("loadingggggg");
  //         updateLoading(false);
  //         updateImageURL(success.path);
  //       },
  //       (fail) => {
  //         updateLoading(false);
  //       }
  //     );
  //   }
  // };

  const beforeUpload = (file) => {
    // Rename the file
    const newFileName = "new-file-name.jpg";
    file.name = newFileName;

    // Return the modified file
    return file;
  };

  const handleUploadCover = (e) => {
    const file = e.file;
    const fileSizeLimit = 16384; // In bytes, example: 16MB
    const minWidth = 303;
    const minHeight = 310;

    // Check if the file size exceeds the limits
    if (file.size / 1024 > fileSizeLimit) {
      message.error("Size exceeds the limits, The size must be less than 16MB");
      return;
    }

    if (file.type.includes("image")) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        // Check if the dimensions exceed the limit
        if (width < minWidth || height < minHeight) {
          message.error(
            "The dimension of the photo must be at least 303px* 310px"
          );
          return;
        }

        let imageFormData = new FormData();
        imageFormData.append("imageEvent", file);
        updateLoading(true);
        uploadEventCover(
          imageFormData,
          (success) => {
            updateLoading(false);
            updateImageURL(success.path);
          },
          (fail) => {
            updateLoading(false);
          }
        );
      };
    }
  };

  // Add new date when event has more than day
  const handleAddNewDateSlot = (event) => {
    event.preventDefault();
    const uniqueID = uuidv4();
    updateDates((preState) => {
      return [...preState, { fullDate: "", times: [], id: uniqueID }];
    });
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  // To prevent admin choose any day before today
  const disabledDate = (current) => {
    // Get today's date
    const today = moment().startOf("day");
    // Can not select days before today
    return current && current < today;
  };

  const options = [
    {
      label: "Online",
      value: "online",
    },
    {
      label: "Offline",
      value: "offline",
    },
  ];

  // Set event location status
  const changeEventStatusHandler = (event) => {
    // console.log(enteredLocation);
    // setEnteredLocation("");
    event.target.value === "offline"
      ? setLocationDisabled(false)
      : setLocationDisabled(true);
  };

  const preventLetters = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const spinnerStyle = {
    width: "150px",
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div className="d-flex flex-column justify-content-center m-auto">
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        requiredMark={false}
        colon={false}
        layout="vertical"
        className="add-event d-flex flex-column justify-content-center align-items-center"
      >
        {/* Upload event cover */}
        <Form.Item
          label=""
          name="imagee"
          rules={[
            {
              required: true,
              message: (
                <div className="text-center">
                  Please, upload photo of the event.
                </div>
              ),
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                } else if (!value.file && value?.includes("jpg")) {
                  return Promise.resolve();
                } else if (value?.file?.type.includes("image")) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  <div className="text-center">Please Upload Images Only</div>
                );
              },
            }),
          ]}
        >
          {/* <ImgCrop
            cropShape="round"
            rotationSlider
            aspect={303 / 310}
            showGrid="true"
          > */}
          <Upload
            disabled={loading}
            action="/upload.do"
            accept="image"
            onChange={handleUploadCover}
            beforeUpload={beforeUpload}
            className="m-auto"
          >
            {loading ? (
              <Spin spinning={loading} style={spinnerStyle} />
            ) : imageURL ? (
              <div className="uploadedImg-container">
                <img src={mediaBaseUrl + imageURL} alt="uploadImg" />
              </div>
            ) : (
              <figure className="thumbnail-figure mt-3">
                <div className="uploadImg-container">
                  <img src={uploadImg} alt="uploadImg" />
                </div>
              </figure>
            )}
          </Upload>
          {/* </ImgCrop> */}
        </Form.Item>

        {/* Search a guru*/}
        <Form.Item
          label="Guru Name"
          name="guruId"
          rules={[{ required: true, message: "Please, Select a guru!" }]}
        >
          <Select
            showSearch
            placeholder="Select a Guru"
            optionFilterProp="children"
            onChange={handleChooseGuru}
            filterOption={filterOption}
            options={gurusList}
          />
        </Form.Item>

        {/* Event name */}
        <Form.Item
          label="Event Name"
          name="name"
          rules={[{ required: true, message: "Please, Enter the event name!" }]}
        >
          <Input />
        </Form.Item>

        {/* Price */}
        <Form.Item
          label="Price"
          name="cost"
          rules={[{ required: true, message: "Please, Enter the price!" }]}
        >
          <InputNumber onKeyPress={preventLetters} placeholder="$120" />
        </Form.Item>

        {/* No. of seats */}
        <Form.Item
          label="Number of seats"
          name="noOfSeats"
          rules={[
            { required: true, message: "Please, Enter the number of seats!" },
          ]}
        >
          <InputNumber onKeyPress={preventLetters} placeholder="10 Seats" />
        </Form.Item>

        {/* Event status */}
        <Form.Item
          defaultValue={"online"}
          label="Status"
          name="status"
          style={{ width: "380px" }}
          onChange={changeEventStatusHandler}
        >
          <Radio.Group style={{ width: "380px" }} defaultValue={"online"}>
            {options.map((option) => {
              return <Radio value={option.value}>{option.label}</Radio>;
            })}
          </Radio.Group>
        </Form.Item>

        {/* Location  */}
        <Form.Item
          label="Location"
          name="location"
          style={{ opacity: `${locationDisabled ? 0.5 : 1}` }}
          rules={[
            {
              required: locationDisabled ? false : true,
              message: locationDisabled ? "" : "Please, Enter a location!",
            },
          ]}
        >
          <Input placeholder="Street 15" disabled={locationDisabled} />
        </Form.Item>

        {/* Dates */}
        {dates.map((date, index) => {
          return (
            <div style={{ position: "relative" }}>
              <Form.Item
                key={date.id}
                id={date.id}
                className="date-input"
                label={index === 0 ? "Time" : ""}
                name={`date${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please, Select a Date time",
                  },
                ]}
              >
                <RangePicker
                  style={{ width: "380px" }}
                  format="YYYY/MM/DD HH:mm a"
                  onChange={onChange}
                  disabledDate={disabledDate}
                  minuteStep={15}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss a"),
                      moment("00:00:00", "HH:mm:ss a"),
                    ],
                  }}
                />
              </Form.Item>
              {index > 0 && (
                <button
                  className="remove-date"
                  onClick={(event) => {
                    event.preventDefault();
                    const newDateList = dates.filter(
                      (dateItem) => dateItem.id !== date.id
                    );
                    updateDates(newDateList);
                  }}
                >
                  <DeleteOutlined />
                </button>
              )}
            </div>
          );
        })}

        <button
          className="border-0 f-14 mainColor cursorPointer ant-select d-flex justify-content-end bg-transparent"
          onClick={handleAddNewDateSlot}
        >
          Add New Date
        </button>
        {/* Description */}
        <Form.Item
          label="Desciption"
          name="description"
          rules={[
            { required: true, message: "Please, Enter the description!" },
          ]}
        >
          <TextArea style={{ resize: "none", height: "100px" }} />
        </Form.Item>
        {/* Actions */}
        <div className="text-center my-4">
          <Button
            className="primaryBtn mx-2 my-4"
            onClick={handleReset}
            loading={loading}
          >
            Discard
          </Button>
          <Button
            className="secondaryBtn mx-2 my-4"
            type="primary"
            loading={loading}
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AddEvent;
