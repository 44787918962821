import { getResource, postResource } from "../../services";

export const GetAllPendingCoaches = (onSuccess, onFail) => {
	const path = "admin/GetAllPendingCoaches";
	getResource(path, onSuccess, onFail);
};
export const AcceptRefusePendingCoaches = (body, onSuccess, onFail) => {
	const path = "admin/AcceptRefusePendingCoaches";
	postResource(path, body, onSuccess, onFail);
};
