import React, { useState, useEffect } from "react";
import AddAdminsModal from "../../../admins/components/AddAdminsModal/AddAdminsModal";
import { Drawer } from "antd";
import { Redirect } from "react-router-dom";
import SideMenu from "../sideMenu/sideMenu";
import HamburgerIcon from "../../../../assets/icons/HamburgerIcon.svg";
//import SearchIcon from "../../../../assets/icons/searchIcon.svg";
import firebase from "firebase/app";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./tableHeader.css";

function TableHeader({
  parent,
  refresh,
  searchValueFun,
  openNofiticationChat,
}) {
  const [isModalVisible, toggleModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataSource, updateDataSource] = useState([]);
  const [newMsgNotification, updateNewMsgNotification] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const [redirectTo, updateRedirectTo] = useState(null);
  // const mq = window.matchMedia("(min-width: 768px)");
  // useEffect(() => {
  //   setVisible(mq.matches);
  // }, [mq.matches]);

  // useEffect(() => {
  //   setVisible(true);
  // }, []);

  const getFBUsers = () => {
    const db = firebase.database();
    var usersRef = db.ref("users");
    if (usersRef) {
      usersRef.on("value", (snapshot) => {
        if (snapshot.val()) {
          const values = Object.values(snapshot.val());
          updateDataSource(values);
        }
      });
    }
  };
  useEffect(() => {
    getFBUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isUnreadMsg = dataSource.every((user) => {
      return user.isAdminRead;
    });

    if (!isUnreadMsg) {
      updateNewMsgNotification(true);
      NotificationManager.info("", "New chat message from Client", 4000, () => {
        // openNofiticationChat();
        updateRedirectTo("liveChat");
      });
    } else {
      updateNewMsgNotification(false);
    }

    // eslint-disable-next-line
  }, [dataSource]);
  if (redirectTo) {
    return (
      <Redirect
        to={{
          pathname: redirectTo,
          state: { opensChat: true },
        }}
      />
    );
  }
  return (
    <div className="tableHeader">
      <NotificationContainer />

      <img src={HamburgerIcon} alt="HamburgerIcon" onClick={showDrawer} />
      {newMsgNotification && <span className="isRepliedDot mx-2"></span>}

      <Drawer
        title="Menu"
        onClose={onClose}
        // visible={visible}
        open={visible}
        placement="left"
      >
        <SideMenu current={parent} onClose={onClose} />
      </Drawer>

      <span className="searchBox">
        {parent === "Admins" && (
          <button
            className="secondaryBtn"
            onClick={() => toggleModal(!isModalVisible)}
          >
            Add Admin
          </button>
        )}

        {parent === "Admins" && (
          <AddAdminsModal
            isModalVisible={isModalVisible}
            toggleModal={() => toggleModal(!isModalVisible)}
            refresh={(val) => refresh(val)}
          />
        )}
      </span>
    </div>
  );
}

export default TableHeader;
