import React from "react";
import "./header.css";
function Header(props) {
	return (
		<header>
			<div className="d-flex ">
				<span>{props.parent}</span>
			</div>
		</header>
	);
}

export default Header;
