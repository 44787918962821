import React, { useState } from "react";
import { Modal, Col, Row, Radio, Button } from "antd";
import { mediaBaseUrl } from "../../../../services";
import moment from "moment";
import "./clientModal.css";
import { useEffect } from "react";
import { ActivatedAndDeactivateUsers } from "../../network";

function ClientModal({ isModalVisible, toggleModal, record }) {
  const [radioValue, setRadioValue] = useState("");
  const [discountPercentage, updateDiscountPercentage] = useState(0);
  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
    ActivatedAndDeactivateUsers(
      {
        isActive: e.target.value === "active" ? true : false,
        id: record._id,
        type: 1,
      },
      (success) => {
        console.log(success);
      },
      (fail) => console.log(fail)
    );
  };

  const handleSubmit = () => {
    toggleModal();
  };

  // const otherLanguages = record.otherLanguages
  // 	? Object.values(record.otherLanguages)
  // 	: [];

  const initials =
    record.clientFirstName &&
    Object.values(record.clientFirstName[0]) +
      Object.values(record.clientLastName[0]);

  useEffect(() => {
    setRadioValue(record.isActive ? "active" : "block");
  }, [setRadioValue, record.isActive]);

  useEffect(() => {
    if (discountPercentage > 100) {
      updateDiscountPercentage(100);
    }

    if (discountPercentage < 0) {
      updateDiscountPercentage(0);
    }
  }, [discountPercentage]);
  return (
    <Modal
      visible={isModalVisible}
      onCancel={toggleModal}
      footer={[]}
      width={"90%"}
      centered
      className="clientModal"
    >
      <div className="clientModalContainer">
        <Row>
          <Col md={14} xs={24}>
            <div className="personalInfoContainer">
              {record.image ? (
                <img
                  src={mediaBaseUrl + record.image}
                  alt="avatar"
                  className="avatarLg"
                />
              ) : (
                <span className="initials">
                  <span>{initials}</span>
                </span>
              )}
              <div style={{ wordBreak: "break-all" }}>
                <h5>{record.clientName}</h5>
                <div>{record.phoneNumber}</div>
                <div style={{ color: "#CFA79D" }}>{record.email}</div>
              </div>
            </div>
          </Col>
          <Col md={10} xs={24}>
            <div className="generalInfoBox">
              <div>
                <div>Gender</div>
                <div>Date of Birth</div>
                {/* <div>Language</div>
								<div>Other Languages</div> */}
              </div>
              <div>
                <div>{record.Gender}</div>
                <div>{moment(record.dateOfBirth).format("DD/MM/YYYY")}</div>
                {/* <div>{record.language}</div>
								<div>
									{otherLanguages.map((lang, i) => {
										return (
											<span key={i} style={{ marginRight: "5px" }}>
												{lang}
											</span>
										);
									})}
								</div> */}
              </div>
            </div>

            <Radio.Group
              onChange={onRadioChange}
              value={radioValue}
              style={{ marginTop: "80px" }}
              size="large"
            >
              <Radio value={"active"}>Active</Radio>
              <Radio value={"block"}>Block</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <div className="btnContainer">
          <Button className="primaryBtn" onClick={() => toggleModal()}>
            Cancel
          </Button>
          <Button className="secondaryBtn" onClick={() => handleSubmit()}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ClientModal;
